/* eslint-disable no-inline-styles/no-inline-styles */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilFavorite, UilNotes, UilStar, UilThumbsUp } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'

import { useSnackbar } from '~/_context/Snackbar'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { useServiceUpdateLessonStatus, useServiceUpdateLessonStatusBatch } from '~/services/Lesson'
import { useGetStudents } from '~/services/Students'
import { useServiceUpdateTrail } from '~/services/TrailEdit'

import {
  AppBarComponent,
  BackNavigationComponent,
  CardNavigationComponent,
  ContainerComponent,
  DividerComponent,
  ModalActionComponent,
  ModalChangeTimeComponent,
  ModalDocumentationComponent,
  ModalDownloadList,
  ModalPlaneComponent,
  ModalStudentManagementComponent,
  SkeletonComponent,
} from '~/components'
import { HeaderLessonFullComponent } from '~/components/Header/HeaderLesson/HeaderLessonFull'
import InactiveTag from '~/components/ui/InactiveTag/InactiveTag'
import { handleStatusStep, StepsTrackingChipComponent } from '~/components/ui/StepsTracking/StepsTracking'

import { IStatus } from '~/pages/private/Fund1/Lesson/interfaces'

import { mapClassShift } from '~/validations/mapClassShift'
import { mapCoStage } from '~/validations/mapCoStage'

import { useLessonPageContext } from './context'
import * as S from './styles'

interface LessonViewProps {
  isOffline?: boolean
}

export const LessonView = ({ isOffline }: LessonViewProps) => {
  const { idLessonMoment, idClass } = useParams()

  const {
    isLoading,
    data,
    refetch,
    dataDetails,
    isLoadingDetails,
    refetchDataDetails,
    isLoadingStudentsGroup,
    errorDataStudentsGroup,
    dataStudentsGroup,
    refetchStudentsGroup,
    isSameUser,
    isClassInStatus,
    handleSchedule,
  } = useLessonPageContext()
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const [showTimeModal, setShowTimeModal] = useState<boolean>(false)
  const [isMomentOutdated, setIsMomentOutdated] = useState<boolean>(false)
  const [showCancelModalLesson, setShowCancelModalLesson] = useState<boolean>(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)
  const [isSame, setIsSame] = useState<boolean>(false)

  const validateDate = (date: string): boolean => {
    const formattedDate = dayjs(date)
    const currentDate = dayjs()
    const isTodayOrBefore = formattedDate.isBefore(currentDate, 'day') && !formattedDate.isSame(currentDate, 'day')
    return isTodayOrBefore
  }
  const handleOpenModalSucess = useCallback(() => {
    setOpenModalSucess(true)
  }, [])

  const handleChangeStatusSheduled = async () => {
    await handleLeaveTrail({
      status: 'AVPE',
      lessonId: Number(idLessonMoment),
    })
    setShowCancelModalLesson(false)
  }

  const handleCloseModalTime = async () => {
    setShowTimeModal(false)
  }

  const {
    mutate: updateTrailProject,
    error: errorData,
    isLoading: loadingData,
    data: dataResponseTrail,
    isSuccess: successTrail,
  } = useServiceUpdateTrail()

  const handleConfirmTrail = async (dataGroup: any) => {
    const foundItem = dataGroup.moments.find((item) => {
      return item.idMoment === data?.idLessonMoment
    })
    setIsSame(foundItem ? true : false)
    await updateTrailProject({
      body: dataGroup,
      classId: Number(data?.class?.idClass),
      lessonId: Number(data?.idLessonTrack),
    })
    setLoading(true)
  }

  const [loadingRequest, setLoading] = useState<boolean>(true)
  const { showSnackbarError } = useSnackbar()

  const status = data?.momentStatus?.coMomentStatus as IStatus

  const { data: dataStudents, isLoading: isLoadingStudents, error: errorDataStudents } = useGetStudents(idClass)

  const updateSubGroupsByMomentStage = () => {
    const subGroupsByMomentStage = dataStudentsGroup?.subGroupsByMomentStage || []

    const missingStudents = dataStudents?.filter(
      (student: { idStudent: number }) =>
        !subGroupsByMomentStage.some((lessonMoment) =>
          lessonMoment.subGroups.some((subGroup: { group: any[] }) =>
            subGroup.group.some((groupStudent: { idStudent: number }) => groupStudent.idStudent === student.idStudent),
          ),
        ),
    )

    const getRandomIndex = (length: number) => Math.floor(Math.random() * length)

    const getRandomElement = (array: string | any[]) => array[getRandomIndex(array.length)]

    missingStudents?.forEach(
      (student: {
        idUserStudent: string
        idStudent: string
        idSchool: string
        coGrade: string
        idNetwork: string
        idUser: string
        txName: string
      }) => {
        const randomLessonMoment = getRandomElement(subGroupsByMomentStage)

        const randomSubGroup = getRandomElement(randomLessonMoment?.subGroups)

        const existingStudent = getRandomElement(randomSubGroup?.group)

        randomSubGroup.group.push({
          idLessonMomentGroup: existingStudent.idLessonMomentGroup,
          idUserStudent: student.idUserStudent,
          idStudent: student.idStudent,
          inAttendance: false,
          idLessonMomentActivity: existingStudent.idLessonMomentActivity,
          idLessonActivity: existingStudent.idLessonActivity,
          studentClass: {
            idClass: existingStudent.studentClass.idClass,
            inStatus: true,
            student: {
              idStudent: student.idStudent,
              idSchool: student.idSchool,
              coGrade: student.coGrade,
              idUserStudent: student.idUserStudent,
              idNetwork: student.idNetwork,
              idUser: student.idUser,
              txName: student.txName,
              inDeleted: false,
            },
          },
          nuOrder: existingStudent.nuOrder,
          nuSubGroup: randomSubGroup.nuSubGroup,
        })
      },
    )

    const updatedDataStudentsGroup = {
      ...dataStudentsGroup,
      subGroupsByMomentStage,
    }

    return updatedDataStudentsGroup
  }

  const dataUpdated = dataStudentsGroup?.group ? updateSubGroupsByMomentStage() : []

  useEffect(() => {
    if (!isLoadingStudents && errorDataStudents) {
      setLoading(true)
    }
  }, [errorDataStudents, isLoadingStudents, showSnackbarError])

  useEffect(() => {
    if (!isLoadingStudents && dataStudents) {
      setLoading(false)
    }
  }, [isLoadingStudents, dataStudents, showSnackbarError])

  useEffect(() => {
    if (!isLoadingStudentsGroup && errorDataStudentsGroup) {
      setLoading(true)
    }
  }, [errorDataStudentsGroup, isLoadingStudentsGroup, showSnackbarError])

  useEffect(() => {
    if (!isLoadingStudentsGroup && dataStudentsGroup) {
      setLoading(false)
    }
  }, [isLoadingStudentsGroup, dataStudentsGroup, showSnackbarError])

  const inStatus = data?.class?.inStatus
  const component = data?.lesson?.lessonComponent
  const dtSchedule = data?.dtSchedule
  const txClassName = data?.class?.txName
  const txGrade = data?.class?.schoolGrade?.grade?.txGrade
  const txMomentStatus = data?.momentStatus?.txMomentStatus
  const txTitle = data?.lesson?.txTitle
  const idLesson = data?.lesson?.lessonStep[0]?.idLesson

  const txProfessorImagePath = data?.professor?.txImagePath
  const txProfessorName = data?.professor?.txName
  const classShift = data?.class?.coClassShift
  const coStage = data?.class?.schoolGrade?.grade.coStage
  const nuLessonTrackGroups = data?.nuLessonTrackGroups
  const nuOrderLessonTrackGroup = data?.nuOrderLessonTrackGroup
  const txTrackTitle = data?.lesson?.txTrackTitle
  const idLessonTrack = data?.idLessonTrack

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showPlanModal, setShowPlanModal] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const [showModalStudentManagement, setShowModalStudentManagement] = useState(false)

  const {
    mutate: handleLeaveTrail,
    isLoading: loadingDataLesson,
    data: dataResponse,
    error: errorDataLesson,
    isSuccess,
    isError,
  } = useServiceUpdateLessonStatus()

  const {
    mutate: handleLeaveTrailBatch,
    isLoading: loadingDataLessonBatch,
    data: dataResponseBatch,
    error: errorDataLessonBatch,
    isSuccess: isSuccessBatch,
    isError: isErrorBatch,
  } = useServiceUpdateLessonStatusBatch()

  const {
    mutate: handleLeaveTrailPlane,
    isLoading: loadingDataLessonPlane,
    data: dataResponsePlane,
    error: errorDataLessonPlane,
  } = useServiceUpdateLessonStatus()

  useEffect(() => {
    if (!loadingDataLessonPlane && errorDataLessonPlane) {
      showSnackbarError(errorDataLessonPlane?.response?.data?.message || 'Ocorreu um erro.')
    } // eslint-disable-next-line
  }, [errorDataLessonPlane, loadingDataLessonPlane, loadingDataLessonPlane, showSnackbarError])

  useEffect(() => {
    if (!loadingDataLessonPlane && dataResponsePlane) {
      setLoading(false)
      setShowPlanModal(false)
      refetch().then(() => {
        setOpenModalSucess(true)
      })
    }
  }, [loadingDataLessonPlane, dataResponsePlane, refetch])

  useEffect(() => {
    if (!loadingDataLesson && errorDataLesson) {
      setLoading(false)
      showSnackbarError(errorDataLesson?.response?.data?.message || 'Ocorreu um erro.')
    }
    if (!loadingDataLessonBatch && errorDataLessonBatch) {
      setLoading(false)
      showSnackbarError(errorDataLessonBatch?.response?.data?.message || 'Ocorreu um erro.')
    }
  }, [errorDataLesson, loadingDataLesson, loadingDataLessonBatch, errorDataLessonBatch, showSnackbarError])

  useEffect(() => {
    if ((!loadingDataLesson && dataResponse) || (!loadingDataLessonBatch && dataResponseBatch)) {
      setLoading(false)
      setShowTimeModal(false)
      refetch().then(() => {
        setOpenModalSucess(true)
      })
      setModalOpen(true)
      setShowCancelModalLesson(false)
    }
  }, [
    isErrorBatch,
    isSuccessBatch,
    loadingDataLessonBatch,
    dataResponseBatch,
    dataResponse,
    handleLeaveTrail,
    handleOpenModalSucess,
    isError,
    isSuccess,
    idLessonMoment,
    loadingDataLesson,
    loadingRequest,
    refetch,
  ])

  useEffect(() => {
    if (!loadingData && errorData) {
      setLoading(false)
      showSnackbarError(errorData?.response.data.message)
    }
  }, [errorData, loadingData, showSnackbarError])

  useEffect(() => {
    if (!loadingData && dataResponseTrail) {
      setLoading(false)
      if (status !== 'AUPL' && isSame) {
        handleLeaveTrail({
          status: 'AUPL',
          lessonId: Number(idLessonMoment),
        })
        setOpenModalSucess(true)
        setShowTimeModal(false)
      } else {
        refetch().then(() => {
          setOpenModalSucess(true)
          setShowTimeModal(false)
        })
      }
    }
  }, [
    dataResponseTrail,
    handleLeaveTrail,
    idLessonMoment,
    isSame,
    loadingData,
    refetch,
    showSnackbarError,
    status,
    successTrail,
  ])

  useEffect(() => {
    setIsMomentOutdated(false)
    if (!isLoading && data && validateDate(data?.dtSchedule)) {
      if (['AUPL', 'PEND'].includes(data?.momentStatus?.coMomentStatus) && !modalOpen) {
        setIsMomentOutdated(true)
      }
    }
  }, [data, isLoading, modalOpen, setIsMomentOutdated])

  useEffect(() => {
    if (!loadingDataLesson && errorDataLesson?.response?.data.message?.length && isError) {
      showSnackbarError(errorDataLesson?.response?.data.message)
    }
  }, [errorDataLesson, isError, loadingDataLesson, showSnackbarError])

  useEffect(() => {
    if (!loadingDataLesson && isSuccess) {
      refetch()
    }
  }, [isSuccess, loadingDataLesson, refetch])

  const handleLeaveSucessTime = async () => {
    setOpenModalSucess(false)
  }

  const handleCancelDocumentation = () => {
    setShowCancelModal(false)
    scrollToCard()
  }

  const cardRef = useRef<HTMLDivElement>(null)
  const scrollToCard = () => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

      setTimeout(() => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect()
          const additionalOffset = rect.top < 0 ? rect.top : 0

          if (additionalOffset < 0) {
            window.scrollBy({ top: additionalOffset, behavior: 'smooth' })
          }
        }
      }, 100)
    }
  }

  const handleOpenCancel = () => {
    setShowCancelModal(true)
  }
  const handleConfirmPlan = (idMoments: number[]) => {
    if (idMoments.length === 0) {
      handleLeaveTrail({
        status: doSearch(status),
        lessonId: Number(idLessonMoment),
      })
    } else {
      handleLeaveTrailBatch({
        idClass,
        idLessonMoments: idMoments,
        status: doSearch(status),
      })
    }

    setShowPlanModal(false)
  }

  const actionConfirmPlane = () => {
    handleLeaveTrailPlane({
      status: doSearch(status),
      lessonId: Number(idLessonMoment),
    })
  }

  const handleOpenPlane = () => {
    setShowPlanModal(true)
  }

  const handleCancelPlane = () => {
    setShowPlanModal(false)
  }

  function doSearch(status: string) {
    switch (status) {
      case 'AUPL':
        handleOpenPlane()
        return 'AVPE'
      case 'AUPE':
        setShowCancelModalLesson(true)
        break
      case 'FINA':
        break
      case 'AVPE':
        handleOpenCancel()
        return 'FINA'
      case 'PEND':
        isMomentOutdated ? setShowCancelModalLesson(true) : handleOpenPlane()
        return 'AUPL'
      case 'INIC':
        return 'FINA'
    }
  }

  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
  }, [])

  const handleOpenModalStudentManagement = () => {
    if (dataStudents && !isLoadingStudents) {
      setShowModalStudentManagement(true)
    }
  }

  const handleCloseModalStudentManagement = () => setShowModalStudentManagement(false)

  const [isScrolling, setIsScrolling] = useState(false)
  const sentinelRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const handleScrollCheck = () => {
      return document.documentElement.scrollHeight > window.innerHeight
    }

    if (handleScrollCheck()) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsScrolling(!entry.isIntersecting)
        },
        {
          root: null,
          threshold: 0.1,
        },
      )

      if (sentinelRef.current) {
        observer.observe(sentinelRef.current)
      }

      return () => {
        if (sentinelRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          observer.unobserve(sentinelRef.current)
        }
      }
    }
  }, [])

  if (isLoading || isLoadingStudents || isLoadingStudents || isLoadingStudentsGroup)
    return (
      <>
        <AppBarComponent />
        <ContainerComponent>
          <S.ContentSkeleton>
            <SkeletonComponent width={'80%'} height={60} />
            <SkeletonComponent width={200} height={60} />
            <SkeletonComponent width={100} height={60} />
            <SkeletonComponent width={'100%'} height={60} variant={'text'} />

            <SkeletonComponent width={'100%'} height={200} variant={'text'} />

            <SkeletonComponent width={'100%'} height={100} variant={'text'} />
            <SkeletonComponent width={'100%'} height={100} variant={'text'} />
            <SkeletonComponent width={'100%'} height={100} variant={'text'} />
          </S.ContentSkeleton>
        </ContainerComponent>
      </>
    )

  return (
    <S.ContainerScroll className="styled-scroll">
      <AppBarComponent />

      {data && (
        <ContainerComponent>
          <S.ContentHeader>
            {!isScrolling ? (
              <S.ContentBack isMobile={Boolean(isMobile)}>
                <BackNavigationComponent path={`/home?coMomentStatus=${data?.momentStatus?.coMomentStatus}`} />
                <S.WrapperActionHeader>
                  <ModalDownloadList files={data.lesson.lessonFile} />
                  <S.WrapperStudents>
                    {isLoading ? (
                      <SkeletonComponent height={20} width={160} variant="rounded" />
                    ) : (
                      <>
                        {!isOffline ? (
                          <>
                            <S.ContainerTrackClass>
                              <S.ClassSpanInfo>{mapClassShift(classShift || '')}</S.ClassSpanInfo>
                              <S.ClassSpanInfo>{mapCoStage(coStage ?? '')}</S.ClassSpanInfo>
                              <S.ClassSpanInfo>{txGrade}</S.ClassSpanInfo>
                              <Tooltip title={txClassName ? txClassName : ''}>
                                <S.ClassSpanInfo>{txClassName}</S.ClassSpanInfo>
                              </Tooltip>
                              {inStatus === false && <InactiveTag />}
                            </S.ContainerTrackClass>
                          </>
                        ) : (
                          <S.WrapperClass>{`${txGrade} - ${txClassName}`}</S.WrapperClass>
                        )}
                      </>
                    )}
                  </S.WrapperStudents>
                </S.WrapperActionHeader>
              </S.ContentBack>
            ) : (
              <S.ContentBackSmall>
                <BackNavigationComponent small path={`/home?coMomentStatus=${data?.momentStatus?.coMomentStatus}`} />
                <S.Title variant="h1">{isLoading ? <SkeletonComponent width={400} /> : txTitle}</S.Title>
              </S.ContentBackSmall>
            )}

            {status && data && component && dataDetails && (
              <HeaderLessonFullComponent
                setShowTimeModal={handleSchedule}
                loading={isLoading}
                isSameUser={isSameUser}
                loadingStatus={loadingDataLesson}
                classBefore={dataDetails?.scheduledLessonMomentByClassAndTrackId?.items}
                projectId={data.idLessonMoment}
                title={txTitle}
                statusCode={status}
                statusText={txMomentStatus}
                dtSchedule={dtSchedule}
                components={component}
                actionStatus={
                  status === 'AVPE' || status === 'PEND' || status === 'AUPL' || status === 'AUPE'
                    ? () => doSearch(status)
                    : () =>
                        handleLeaveTrail({
                          status: doSearch(status),
                          lessonId: Number(idLessonMoment),
                        })
                }
                avatas={dataStudents}
                dateHour={dayjs(dtSchedule).format('DD/MM/YYYY')}
                lessonYear={`${txGrade} - ${txClassName}`}
                actionManageStudents={handleOpenModalStudentManagement}
                coStage={coStage}
                classShift={classShift}
                isSameProfessor={isSameUser}
                isClassInStatus={data?.class.inStatus}
                professorImagePath={txProfessorImagePath}
                professorName={txProfessorName}
                nuLessonTrackGroups={nuLessonTrackGroups}
                nuOrderLessonTrackGroup={nuOrderLessonTrackGroup}
                isSameLesson={true}
                txTrackTitle={txTrackTitle}
                idMomentTrack={idLessonTrack}
                idClass={idClass}
                isScrolling={isScrolling}
                idLesson={idLesson}
                isMobile={isMobile}
              />
            )}
          </S.ContentHeader>

          <S.Content>
            <S.ContentDescription isMobile={Boolean(isMobile)} isScrolling={isScrolling}>
              <S.ContentStep>
                {handleStatusStep(status) === 'Planning' ? (
                  <StepsTrackingChipComponent
                    stepNumber={1}
                    text={'Planejamento e Ação'}
                    variant={'onStep'}
                    status={status}
                  />
                ) : (
                  <StepsTrackingChipComponent
                    stepNumber={1}
                    text={'Planejamento e Ação'}
                    variant={'checked'}
                    status={status}
                  />
                )}
              </S.ContentStep>

              <CardNavigationComponent
                label="Orientações da Aula"
                testId="orientations-section-navigation"
                pathNavigation={
                  status === 'AGEN'
                    ? `/class/${idClass}/planning/${idLesson}`
                    : `/class/${idClass}/planning/${idLesson}/lesson/${idLessonMoment}`
                }
              />
            </S.ContentDescription>

            <DividerComponent />
            <div ref={sentinelRef} style={{ height: '1px' }}></div>
            <S.ContentNavigation>
              {handleStatusStep(status) === 'Assessment' ? (
                <StepsTrackingChipComponent
                  stepNumber={2}
                  text={'Documentação e Avaliação'}
                  variant={'onStep'}
                  status={status}
                />
              ) : (
                <StepsTrackingChipComponent
                  stepNumber={2}
                  text={'Documentação e Avaliação'}
                  variant={
                    handleStatusStep(status) === 'Action' || handleStatusStep(status) === 'Planning'
                      ? 'disable'
                      : 'checked'
                  }
                  status={status}
                />
              )}
              <CardNavigationComponent
                icon={<UilNotes />}
                label="Documentações"
                disabled={!['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
                pathNavigation={`/class/${idClass}/documentations/${idLessonMoment}/lesson/${idLesson}`}
                testId="document-section-navigation"
              />
              <S.CardRef ref={cardRef}>
                <CardNavigationComponent
                  icon={<UilFavorite />}
                  disabled={!['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
                  label="Avaliações"
                  pathNavigation={`/class/${idClass}/lesson-assessment/${idLessonMoment}/lesson/${idLesson}`}
                  testId="assessment-section-navigation"
                />
              </S.CardRef>
            </S.ContentNavigation>
          </S.Content>

          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(data?.momentStatus?.coMomentStatus) && isSameUser && (
            <ModalDocumentationComponent
              idLessonMoment={Number(idLessonMoment)}
              idLesson={Number(data.lesson.idLesson)}
              variant="floater"
              isClassInStatus={isClassInStatus}
            />
          )}

          {status === 'PEND' ? (
            <ModalPlaneComponent
              actionCofirm={handleConfirmPlan}
              icon={<UilThumbsUp size={24} color="#29CC5F" />}
              titleLabel="Parabéns por planejar mais uma aula!"
              open={showPlanModal}
              idLesson={data?.lesson.idLesson}
              description={'Tem certeza que deseja confirmar o planejamento dessa aula?'}
              actionCloseModal={handleCancelPlane}
              confirmCancelText="Confirmar Planejamento"
            />
          ) : (
            <ModalPlaneComponent
              actionCofirm={actionConfirmPlane}
              icon={<UilThumbsUp size={24} color="#29CC5F" />}
              titleLabel="Parabéns por realizar mais uma aula!"
              open={showPlanModal}
              description={'Tem certeza que deseja confirmar a realização dessa aula?'}
              actionCloseModal={handleCancelPlane}
              confirmCancelText="Confirmar realização"
            />
          )}

          <ModalActionComponent
            actionCofirm={() => {
              handleLeaveTrail({
                status: doSearch(status),
                lessonId: Number(idLessonMoment),
              }),
                setShowCancelModal(false)
            }}
            icon={<UilStar size={20} color="#0494fc" />}
            title={
              'Após confirmar a avaliação, não será mais possível fazer alterações e esta aula irá constar no histórico individual dos alunos.'
            }
            description="Tem certeza que deseja confimar a avaliação?"
            open={showCancelModal}
            actionCloseModal={handleCancelDocumentation}
            confirmCancelText="Confirmar Avaliação"
            returnBtnText="Revisar Avaliação"
            infoWidth={700}
            isFullModal={true}
            isConfirm={true}
            idClass={idClass as number}
            IdMoment={Number(idLessonMoment)}
          ></ModalActionComponent>

          <ModalActionComponent
            open={openModalSucess}
            title={`Aula confirmada com sucesso!`}
            actionCloseModal={() => handleLeaveSucessTime()}
            infoWidth={700}
            isFullModal={false}
          />

          {isSameUser && (
            <ModalChangeTimeComponent
              open={showCancelModalLesson}
              isLoadingRequest={loadingDataLesson}
              actionScheduleModal={handleSchedule}
              actionCofirm={() => handleChangeStatusSheduled()}
              dateHour={dtSchedule}
              statusCode={data?.momentStatus?.coMomentStatus}
              statusText={data?.momentStatus?.txMomentStatus}
              actionCloseModal={() => setShowCancelModalLesson(false)}
              loadingConfirm={loadingDataLesson}
              lessonYear={`${data?.class?.txName} - ${data?.class?.schoolGrade?.grade?.txGrade}`}
              titleLabel={data?.lesson.txTitle}
              classShift={classShift}
              coStage={coStage}
              txGrade={txGrade}
            />
          )}

          {data && dataUpdated && dataUpdated?.group && !errorDataStudentsGroup && (
            <ModalStudentManagementComponent
              openModal={showModalStudentManagement}
              actionCloseModal={handleCloseModalStudentManagement}
              dataClass={data}
              type={'F1'}
              classID={data?.class.idClass}
              idLessonMoment={Number(idLessonMoment)}
              dataStudentsGroup={dataUpdated}
              refetchStudents={refetchStudentsGroup}
            />
          )}
        </ContainerComponent>
      )}
    </S.ContainerScroll>
  )
}
