import { gql } from 'graphql-request'

export const GET_PROJECT_BY_ID = gql`
  query getProjectByID($projectId: Int!) {
    projectById(projectId: $projectId) {
      txTitle
      idProject

      txDescription

      projectStage {
        projectStageGroup {
          projectTrackStage {
            idProjectTrackStage
            txTitle
            txDescription
            txGuidanceCode
          }
        }
        idProjectStage
        idProject
        projectStageEvidence {
          evidence {
            idEvidence
            txName
            idAbility
            dtInserted
            ability {
              competence {
                idCompetence
                txImagePath
                txName
                txPrimaryColor
              }
              idAbility
              idCompetence
              txName
              txImagePath
              txBgSecondaryColor
              txPrimaryColor
            }
          }
        }
      }

      projectCategory {
        idProject
        idCategory
        categoryGrade {
          coGrade
        }
      }
      grade {
        coGrade
        coStage
      }
    }
  }
`
