import { useState } from 'react'

import { UilTrash, UilFileBookmarkAlt, UilCalender, UilCheck } from '@iconscout/react-unicons'
import dayjs from 'dayjs'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { IIdenticalLessonMoments, useGetIdenticalLessonMoments } from '~/services/LessonMoment'
import { IIdenticalProjectMoments, useGetIdenticalProjectMoments } from '~/services/ProjectMoment'

import { ButtonComponent, ChipComponent } from '~/components'
import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import { ModalComponentBase } from '../ModalBase/Modal'
import { IModalPlaneProps } from './ModalPlane.interfaces'
import * as S from './ModalPlane.styles'

export const ModalPlaneComponent = ({
  open,
  actionCloseModal,
  actionCofirm,
  titleLabel,
  icon,
  color,
  returnBtnText = 'Cancelar',
  confirmCancelText,
  description,
  idLesson,
  idProject,
  isAlert,
}: IModalPlaneProps) => {
  const { width } = useWindowDimensions()
  const [selectedIdMoments, setSelectedIdMoments] = useState<number[]>([])
  const isLessonValid = Boolean(idLesson)
  const isProjectValid = Boolean(idProject)
  const { data: dataLesson, isLoading: isLoadingLesson } = useGetIdenticalLessonMoments(idLesson!, {
    enabled: isLessonValid,
  })

  const { data: dataProject, isLoading: isLoadingProject } = useGetIdenticalProjectMoments(idProject!, {
    enabled: isProjectValid,
  })

  const data = idLesson ? dataLesson : dataProject

  if (data && data.length > 1) {
    return (
      // eslint-disable-next-line no-inline-styles/no-inline-styles

      <ModalComponentBase open={open} actionCloseModal={actionCloseModal} maxWidth={'560px'}>
        <S.Container>
          <S.ContentIcon bgColor={'#E8F6FF'}>
            <UilFileBookmarkAlt size={30} color="#0095FF" />
          </S.ContentIcon>

          <S.Title variant="h1">Você possui essa mesma aula para planejar em outras turmas</S.Title>

          <S.TextDescription variant="caption">
            Deseja confirmar o planejamento em lote de todas as aulas?
          </S.TextDescription>

          <S.FormCheckboxList>
            {data.map((moment: IIdenticalLessonMoments | IIdenticalProjectMoments) => {
              const id = 'idLessonMoment' in moment ? moment.idLessonMoment : moment.idProjectMoment
              return (
                <S.FormCheckbox
                  key={id}
                  onClick={() => {
                    if (selectedIdMoments.includes(id)) {
                      setSelectedIdMoments(selectedIdMoments.filter((momentId) => momentId !== Number(id)))
                    } else {
                      setSelectedIdMoments([...selectedIdMoments, Number(id)])
                    }
                  }}
                >
                  <S.Checkbox
                    id={`checkbox-${id}`}
                    name={`checkbox-${id}`}
                    type="checkbox"
                    checked={selectedIdMoments.includes(id)}
                    onChange={(e) => {
                      const checkboxId = Number(e.target.id.split('-')[1])
                      if (e.target.checked) {
                        setSelectedIdMoments([...selectedIdMoments, checkboxId])
                      } else {
                        setSelectedIdMoments(selectedIdMoments.filter((momentId) => momentId !== checkboxId))
                      }
                    }}
                  />
                  <S.CheckboxLabel>
                    <S.CheckboxSpan>{moment.txStageName}</S.CheckboxSpan>
                    <S.CheckboxSpan>{moment.txGrade}</S.CheckboxSpan>
                    <DSTooltip text={moment.txClassName as string} position={'top'}>
                      <S.CheckboxSpan className={'resume'}>Turma {moment.txClassName}</S.CheckboxSpan>
                    </DSTooltip>
                  </S.CheckboxLabel>
                  <ChipComponent
                    type={'light'}
                    label={dayjs(moment.dtSchedule).format('DD/MM/YYYY')}
                    icon={<UilCalender size={16} />}
                  />
                </S.FormCheckbox>
              )
            })}
          </S.FormCheckboxList>

          <S.ContentButtons>
            <ButtonComponent
              text={'Confirmar para todas turmas'}
              variant="solid"
              size="large"
              color={color}
              onClick={() => actionCofirm(selectedIdMoments)}
              fullWidth={(width ?? 0) < 700}
            />
            <ButtonComponent
              text={returnBtnText}
              variant="text"
              size="large"
              fontWeight="normal"
              onClick={actionCloseModal}
              fullWidth={(width ?? 0) < 700}
              // eslint-disable-next-line no-inline-styles/no-inline-styles
              style={{ color: 'black' }}
            />
          </S.ContentButtons>
        </S.Container>
      </ModalComponentBase>
    )
  }

  return (
    // eslint-disable-next-line no-inline-styles/no-inline-styles
    <ModalComponentBase open={open} actionCloseModal={actionCloseModal} maxWidth={'448px'}>
      <S.Container>
        <S.ContentIcon bgColor={isAlert ? '#FFF1E1' : '#E1FFEB'}>
          {icon ? icon : <UilTrash size={30} color="#D44333" />}
        </S.ContentIcon>

        <S.Title variant="h1">{titleLabel}</S.Title>

        <S.TextDescription variant="caption">{description}</S.TextDescription>

        <S.ContentButtons>
          <ButtonComponent
            text={confirmCancelText}
            variant="solid"
            size="large"
            color={color}
            iconEnd={<UilCheck size={20} color={'#FFFFFF'} />}
            onClick={() => actionCofirm([])}
            fullWidth={(width ?? 0) < 700}
            data-testid="confirm-button"
          />

          <ButtonComponent
            text={returnBtnText}
            variant="text"
            size="large"
            fontWeight="normal"
            onClick={actionCloseModal}
            fullWidth={(width ?? 0) < 700}
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            style={{ color: 'black' }}
            data-testid="cancel-button"
          />
        </S.ContentButtons>
      </S.Container>
    </ModalComponentBase>
  )
}
