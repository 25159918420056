import { AvatarComponent } from '~/components'

import { ICardStudentComponentProps } from './CardStudent.interfaces'
import * as S from './CardStudent.styles'

export const CardStudentComponent = ({ student, index, actionChangePicture }: ICardStudentComponentProps) => {
  return (
    <S.CardStudent data-testid={`card-student-${index}`} onClick={actionChangePicture}>
      <S.CardWrapper>
        <AvatarComponent label={student?.txName} photoUrl={student?.txImagePath} size="mediumLarge" />

        <S.TextWrapper>
          <S.StudentName>{student?.txName}</S.StudentName>

          <S.ClassName>
            {student.classGrade} - {student.className}
          </S.ClassName>
        </S.TextWrapper>
      </S.CardWrapper>
    </S.CardStudent>
  )
}
