import { useRef } from 'react'

import { IconComponent } from '~/components'

import { IDropdownSkillComponentProps } from './DropdownSkill.interfaces'
import * as S from './DropdownSkill.styles'

export const DropdownSkillComponent = ({ iconSkill, txSkillName }: IDropdownSkillComponentProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  return (
    <S.DropdownSkillContainer ref={dropdownRef}>
      <S.ContentPreview>
        <S.Wrapper>
          <IconComponent color="primary" fontWeight="normal" code={iconSkill} size="xsmall" />

          <S.Title variant="caption">{txSkillName}</S.Title>
        </S.Wrapper>
      </S.ContentPreview>
    </S.DropdownSkillContainer>
  )
}
