import {
  UilFileCheckAlt,
  UilFileEditAlt,
  UilFileExclamationAlt,
  UilFileUploadAlt,
  UilInfoCircle,
} from '@iconscout/react-unicons'
import { Box } from '@mui/material'

import { SkeletonComponent } from '~/components'

import { CardFilterProps } from './CardFilter.interfaces'
import * as S from './CardFilter.styles'

export const CardFilterComponent = ({
  type,
  label,
  action,
  checked,
  setChecked,
  loading,
  onClick,
  count,
  index,
}: CardFilterProps) => {
  const iconSwitch = (type: string) => {
    switch (type) {
      case 'toPlan':
        return <UilFileEditAlt size={80} />
      case 'toPerform':
        return <UilFileCheckAlt size={80} />
      case 'pending':
        return <UilFileExclamationAlt size={80} />
      case 'toDocumentAndEvaluate':
        return <UilFileUploadAlt size={80} />
      default:
        return null
    }
  }

  const labelSwitch = (type: string) => {
    switch (type) {
      case 'toPlan':
        return `Todas as aulas
        que você precisa planejar nos
        próximos 14 dias. Algumas aulas
        podem estar repetidas em diversas
        turmas. Aproveite para panejá-las
        em lote acessando uma aula e
        clicando em Planejamento Realizado.`
      case 'toPerform':
        return `Todas as aulas
        que você deve realizar hoje
        e as aulas prontas para realizar
        nos próximos dias`
      case 'pending':
        return `Todas as aulas que não foram
        definidas como concluídas no dia
        em que estavam marcadas.`
      case 'toDocumentAndEvaluate':
        return `Todas as aulas prontas para serem
        documentadas, avaliadas e finalizadas.`
      default:
        return null
    }
  }
  return (
    <>
      <S.CardContainer
        data-testid={`status-box-${type}`}
        onClick={onClick}
        variant={type}
        selected={checked}
        role={'button'}
      >
        <Box display={'flex'} alignItems={'center'} ml={'-55px'}>
          <S.WrapperAbsolute>
            <S.IconBox selected={checked} variant={type}>
              {iconSwitch(type)}
            </S.IconBox>
          </S.WrapperAbsolute>

          <S.ContentText>
            {loading ? (
              <SkeletonComponent variant="circular" height={24} width={24} />
            ) : (
              <>
                <S.TextDescription selected={checked}>{count}</S.TextDescription>
              </>
            )}
            <S.TextLabel selected={checked}>{label}</S.TextLabel>
          </S.ContentText>
        </Box>

        <S.TooltipLabel
          title={labelSwitch(type)}
          placement="top"
          arrow={true}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#000',
                '& .MuiTooltip-arrow': {
                  color: 'common.black',
                },
              },
            },
          }}
        >
          <S.WrapperInfo selected={checked} variant={type}>
            <UilInfoCircle size={16} />
          </S.WrapperInfo>
        </S.TooltipLabel>
      </S.CardContainer>
    </>
  )
}
