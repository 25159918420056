import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { UilExclamationTriangle } from '@iconscout/react-unicons'
import * as Sentry from '@sentry/browser'
import { gaEvents } from '~/events'
import jwt_decode from 'jwt-decode'
import userflow from 'userflow.js'

import { updateUnleashContext } from '~/services/Unleash'

import packageJson from '../../../package.json'
import { defaultUserState } from './Auth.default.states'
import { DecodedToken, IAuthContextProps, IAuthProviderProps, IUserProps } from './Auth.interfaces'

const AuthContext = createContext<IAuthContextProps>({
  isAuthenticated: false,
  authThirdParty: false,
  user: defaultUserState,
  token: '',
  authenticatedUser: () => defaultUserState,
  handleLogout: () => Object,
})

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<IUserProps | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [authThirdParty, setAuthThirdParty] = useState<boolean>(false)

  const authenticated = useCallback(() => {
    const currentISODate = new Date().toISOString()

    const authStorage = localStorage.getItem('@LEKTO:auth')
    const userStorage: string | null = localStorage.getItem('@LEKTO:user')
    const tokenStorage = localStorage.getItem('@LEKTO:token')

    if (authStorage === 'true' && userStorage) {
      const parsedUser = JSON.parse(userStorage)

      setIsAuthenticated(Boolean(authStorage))
      setUser(parsedUser)
      setToken(tokenStorage)

      userflow.identify(String(parsedUser.id_user), {
        name: parsedUser.name,
        email: parsedUser.email,
        signed_up_at: currentISODate,
        locale: parsedUser.locale,
        roles: parsedUser.role,
        networkId: parsedUser.id_network,
      })
    }
  }, [])

  const handleLogout = useCallback(() => {
    setIsAuthenticated(false)
    setUser(null)
    setToken(null)
    Sentry.setUser(null)
    updateUnleashContext({
      userId: undefined,
      properties: {
        idUser: '',
        version: packageJson.version,
      },
    })

    localStorage.clear()
  }, [])

  const authenticatedUser = useCallback(
    (token: string, isAuthThirdParty: boolean) => {
      const decodedUser: IUserProps = jwt_decode(token)

      // Verifica se o usuário possui um dos perfis permitidos
      const allowedRoles = ['tutor', 'pais']
      const hasAllowedRole = decodedUser.role.some((role) => allowedRoles.includes(role))
      if (!hasAllowedRole) {
        toast.error(<span data-testid="msg-error">Seu perfil não possui autorização para realizar o acesso!</span>, {
          icon: <UilExclamationTriangle color="#fff" size="28" />,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
        handleLogout()
        return null
      }

      Sentry.setUser({ id: decodedUser.id_user.toString(), email: decodedUser.email, username: decodedUser.name })
      updateUnleashContext({ userId: decodedUser.email })
      const currentISODate = new Date().toISOString()
      localStorage.setItem('@LEKTO:auth', 'true')
      localStorage.setItem('@LEKTO:user', JSON.stringify(decodedUser))
      localStorage.setItem('@LEKTO:token', token)
      sessionStorage.setItem('@LEKTO:notificationModal', 'true')
      setAuthThirdParty(isAuthThirdParty)
      authenticated()
      gaEvents.eventFormLogin()
      if (isAuthThirdParty) {
        gaEvents.eventAuthLogin()
      }

      userflow.identify(String(decodedUser?.id_user), {
        name: decodedUser?.name,
        email: decodedUser?.email,
        signed_up_at: currentISODate,
      })

      return decodedUser
    },
    [authenticated, handleLogout],
  )

  useEffect(() => {
    authenticated()

    if (token) {
      const decodedToken: DecodedToken = jwt_decode(token)

      if (decodedToken.exp * 1000 < Date.now()) {
        handleLogout()
      }
    }
  }, [authenticated, token, handleLogout])

  const authProviderValues = useMemo(
    () => ({ isAuthenticated, authThirdParty, user, token, authenticatedUser, handleLogout }),
    [isAuthenticated, authThirdParty, user, token, authenticatedUser, handleLogout],
  )

  return <AuthContext.Provider value={authProviderValues}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
