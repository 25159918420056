import React, { useEffect, useRef, useState } from 'react'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AppBarComponent, ContainerComponent } from '~/components'

import {
  FixedEvidencesComponent,
  HeaderPlanning,
  SkeletonLoading,
  StepAccordion,
  VariableEvidencesComponent,
} from '~/containers/private/Fund1/Planning'
import { OrientationItem } from '~/containers/private/Fund1/Planning/StepAccordion/StepAccordion.interfaces'

import { useLessonPlanningPageContext } from '~/pages/private/Fund1/Planning/context'

import { LessonActivity } from './interface'
import * as S from './styles'

export const LessonPlanningView = () => {
  const {
    evidencesByActivityOnStepTwo,
    isLoading,
    fixedEvidences,
    guidance,
    steps,
    txTitleLesson,
    status,
    LessonOrder,
    trackTitle,
  } = useLessonPlanningPageContext()

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const variablesRefContainer = useRef<HTMLDivElement>(null)
  const overallStepRef = useRef<HTMLDivElement>(null)
  const firstStepRef = useRef<HTMLDivElement>(null)
  const secondStepRef = useRef<HTMLDivElement>(null)
  const thirdStepRef = useRef<HTMLDivElement>(null)

  const [expadedGeneralOrientation, setExpadedGeneralOrientation] = useState(true)
  const [expadedFirstStep, setExpadedFirstStep] = useState(true)
  const [expadedSecondStep, setExpadedSecondStep] = useState(true)
  const [expadedThirdStep, setExpadedThirdStep] = useState(true)

  const [activedTab, setActivedTab] = useState(0)
  const [hasVariableEvidence, setHasVariableEvidence] = useState<LessonActivity[]>([])

  const scrollToNextStep = (ref: React.RefObject<HTMLDivElement>) => {
    const headerHeight = document.getElementById('header')?.clientHeight ?? 0
    const position = isMobile ? headerHeight - 20 : headerHeight + 50
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - position,
      })
    }
  }

  const handleSetActiveTab = (newActivedTab: number) => {
    switch (newActivedTab) {
      case 0:
        scrollToNextStep(variablesRefContainer)
        break
      case 1:
        setExpadedFirstStep(true)
        setTimeout(
          () => {
            scrollToNextStep(firstStepRef)
          },
          expadedFirstStep ? 0 : 400,
        )
        break
      case 2:
        setExpadedSecondStep(true)
        setTimeout(
          () => {
            scrollToNextStep(secondStepRef)
          },
          expadedSecondStep ? 0 : 400,
        )
        break
      case 3:
        setExpadedThirdStep(true)
        setTimeout(
          () => {
            scrollToNextStep(thirdStepRef)
          },
          expadedThirdStep ? 0 : 400,
        )
        break
      default:
        break
    }
    setActivedTab(newActivedTab)
  }

  const handleScroll = () => {
    const overallStepOffset = overallStepRef.current?.offsetTop ?? 0
    const firstStepOffset = firstStepRef.current?.offsetTop ?? 0
    const secondStepOffset = secondStepRef.current?.offsetTop ?? 0
    const thirdStepOffset = thirdStepRef.current?.offsetTop ?? 0

    const scrollPosition = window.scrollY + 280

    if (scrollPosition >= overallStepOffset && scrollPosition < firstStepOffset) {
      setActivedTab(0)
    } else if (scrollPosition >= firstStepOffset && scrollPosition < secondStepOffset) {
      setActivedTab(1)
    } else if (scrollPosition >= secondStepOffset && scrollPosition < thirdStepOffset) {
      setActivedTab(2)
    } else if (scrollPosition >= thirdStepOffset) {
      setActivedTab(3)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setHasVariableEvidence(
        evidencesByActivityOnStepTwo?.filter((activity) => {
          return activity?.lessonActivityEvidence?.find((evidence) => evidence?.coEvidenceType === 'VAR')
        }),
      )
    }
  }, [evidencesByActivityOnStepTwo, isLoading])

  const [expandedAccordions, setExpandedAccordions] = useState<Record<number, boolean>>({})

  const toggleAccordion = (index: number) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            <HeaderPlanning
              LessonOrder={LessonOrder}
              trackTitle={trackTitle}
              status={status}
              lessonOrientations={steps?.[0]?.lessonActivity?.[0]?.lessonActivityOrientation}
              txNameLesson={txTitleLesson}
              handleSetActiveTab={handleSetActiveTab}
              activedTab={activedTab}
              steps={steps}
              guidance={guidance}
              fixedEvidences={fixedEvidences}
              evidencesByActivityOnStepTwo={evidencesByActivityOnStepTwo}
            />
            <S.Container>
              <S.FixedEvidencesContainer>
                <FixedEvidencesComponent steps={fixedEvidences} />
              </S.FixedEvidencesContainer>
              {hasVariableEvidence && hasVariableEvidence.length > 0 && (
                <S.VariableEvidencesContainer ref={variablesRefContainer}>
                  <VariableEvidencesComponent steps={evidencesByActivityOnStepTwo} />
                </S.VariableEvidencesContainer>
              )}
              {guidance.length > 0 && (
                <StepAccordion
                  key={'default'}
                  title="Orientações Gerais"
                  expaded={expadedGeneralOrientation}
                  onToggle={() => setExpadedGeneralOrientation(!expadedGeneralOrientation)}
                  orientationItems={[
                    {
                      txOrientationCode: guidance,
                      lessonActivitySupportReference: [],
                    },
                  ]}
                  supportReferences={[]}
                  accordionRef={overallStepRef}
                  type="default"
                />
              )}
              {steps?.[0]?.lessonActivity?.[0]?.lessonActivityOrientation?.map(
                (orientation: OrientationItem, index: number) => (
                  <StepAccordion
                    key={`orientation-${index}`}
                    title={orientation?.txTitle}
                    orientationItems={orientation ? [orientation] : []}
                    expaded={expandedAccordions[index] || false}
                    onToggle={() => toggleAccordion(index)}
                    supportReferences={orientation?.lessonActivitySupportReference || []}
                    type="firstStep"
                    accordionRef={firstStepRef}
                  />
                ),
              )}
            </S.Container>
          </>
        )}
      </ContainerComponent>
    </>
  )
}
