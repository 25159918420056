import { useState } from 'react'

import { UilEye, UilFilter } from '@iconscout/react-unicons'
import { Checkbox, Tooltip } from '@mui/material'

import { ButtonComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  loading,
  showInactive,
  handleDisabledClasses,
  classes,
  handleChangeClassesSelected,
  isLoadingFilterClasses,
  classesSelected,
  haveInactive,
}: IHeaderComponentProps) => {
  const [anchorElFilterMenu, setAnchorElFilterMenu] = useState<null | HTMLElement>(null)

  const handleOpenFilterMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElFilterMenu(event.currentTarget)
  }

  const handleCloseFilterMenu = () => setAnchorElFilterMenu(null)

  return (
    <S.HeaderContainer component="header">
      <S.Wrapper>
        <S.Title variant="h1">Trilhas</S.Title>
        <S.HeaderBtns>
          <Tooltip placement="top" title={haveInactive && showInactive ? 'Você não possui turmas inativas' : ''}>
            <ButtonComponent
              variant={haveInactive && showInactive ? 'disabled' : showInactive ? 'solid' : 'animated'}
              size="small-squared"
              text={showInactive ? 'Ocultar turmas inativas' : 'Exibir turmas inativas'}
              fontWeight="normal"
              iconEnd={<UilEye size={16} />}
              sizeLoading={12}
              data-testid={showInactive ? 'hide-inactive-classes' : 'show-inactive-classes'}
              animation={true}
              loading={loading}
              onClick={() => handleDisabledClasses()}
              disabled={haveInactive && showInactive}
            />
          </Tooltip>

          <S.BoxContainer>
            <ButtonComponent
              variant={'animated'}
              size="small-squared"
              text={'Filtros'}
              fontWeight="normal"
              data-testid={'button-filter-classes'}
              sizeLoading={12}
              iconEnd={<UilFilter size={16} color="#0095FF" />}
              animation={true}
              loading={loading}
              onClick={handleOpenFilterMenu}
            />
            <S.ButtonSelectClass
              anchorEl={anchorElFilterMenu}
              open={Boolean(anchorElFilterMenu)}
              onClose={handleCloseFilterMenu}
            >
              <S.BoxOverflow>
                <S.WrapperMenuItemFilter key="all">
                  <Checkbox
                    disabled={isLoadingFilterClasses}
                    data-testid={`check-all-years`}
                    checked={classesSelected.length === 0}
                    onChange={() => handleChangeClassesSelected('all')}
                  />
                  <span>Todos os anos</span>
                </S.WrapperMenuItemFilter>
                {classes?.map((item) => {
                  return (
                    <S.LabelMenuItemFilter key={item.coStage}>
                      <S.LabelCategoryGrade>{item.items[0].stage.txName}</S.LabelCategoryGrade>
                      {item.items.map((grade) => {
                        return (
                          <S.WrapperMenuItemFilter key={grade.coGrade}>
                            <Checkbox
                              disabled={isLoadingFilterClasses}
                              data-testid={`check-filter-${grade.coGrade}`}
                              checked={classesSelected.includes(grade.coGrade)}
                              onChange={() => handleChangeClassesSelected(grade.coGrade)}
                            />
                            <span>{grade.txGrade}</span>
                          </S.WrapperMenuItemFilter>
                        )
                      })}
                    </S.LabelMenuItemFilter>
                  )
                })}
              </S.BoxOverflow>
            </S.ButtonSelectClass>
          </S.BoxContainer>
        </S.HeaderBtns>
      </S.Wrapper>
    </S.HeaderContainer>
  )
}
