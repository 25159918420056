import React, { useEffect, useRef, useState } from 'react'

import { UilArrowDown, UilArrowLeft, UilArrowRight, UilArrowUpRight } from '@iconscout/react-unicons'
import { Box, Typography } from '@mui/material'
import parse from 'html-react-parser'

import { DividerComponent, IconComponent } from '~/components'

import { ProjectMomentStage } from '~/pages/private/Fund2/Project/interfaces'

import { colorsStepsActived } from '~/validations'

import * as S from './StepAccordion.styles'

interface ProjectProps {
  title: string
  accordionRef: React.RefObject<HTMLDivElement>
  key: string
  stage: ProjectMomentStage
  expanded?: boolean
  onToggle: () => void
  index: number
}

interface CustomHtmlProps {
  htmlContent: string
}

interface ProjectGeneralOrientationProps {
  title: string
  accordionRef: React.RefObject<HTMLDivElement>
  key: string
}

export const ProjectGeneralOrientation = ({ title, accordionRef, key }: ProjectGeneralOrientationProps) => {
  return (
    <S.AccordionContainer key={key} defaultExpanded ref={accordionRef}>
      <S.AccordionHeader
        expandIcon={
          <S.CircleIcon>
            <UilArrowDown />
          </S.CircleIcon>
        }
      >
        <S.AccordionTitle color={`#0095FF`}>Orientações Gerais</S.AccordionTitle>
      </S.AccordionHeader>
      <Box mt={2}>
        <S.AccordionDetailsContent>
          <S.ContentParagraphs>
            <S.Paragraph>
              <S.TextParagraph>{title ? parse(String(title)) : ''} </S.TextParagraph>
            </S.Paragraph>
          </S.ContentParagraphs>
        </S.AccordionDetailsContent>
      </Box>
    </S.AccordionContainer>
  )
}

export const ProjectStepAccordion = ({
  title,
  accordionRef,
  key,
  stage,
  expanded = true,
  onToggle,
  index,
}: ProjectProps) => {
  const [maxHeight, setMaxHeight] = useState(0)
  const cardRef = useRef<Array<HTMLLIElement | null>>([])
  const getReferences = () => {
    const reference = stage?.projectStage?.projectStageOrientation?.find(
      (reference) => reference?.projectStageSupportReference,
    )

    return reference?.projectStageSupportReference || []
  }
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = 4

  const totalCards = getReferences()?.length

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleNextClick = () => {
    if (currentIndex < totalCards - itemsPerPage) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const isPrevDisabled = currentIndex === 0
  const isNextDisabled = currentIndex >= totalCards - itemsPerPage

  useEffect(() => {
    const heights = cardRef.current.map((ref) => ref?.clientHeight)
    if (heights && heights.length > 0) {
      setMaxHeight(Math.max(...(heights.filter((height) => height !== undefined) as number[])))
    }
  }, [])

  const CustomHtml: React.FC<CustomHtmlProps> = ({ htmlContent }) => {
    const modifiedHtml = htmlContent?.replace(/color: rgb\(\d+, \d+, \d+\)/g, 'color: black')

    return <S.TextParagraph dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
  }

  const color =
    index === 0
      ? colorsStepsActived('firstStep')
      : index === 1
      ? colorsStepsActived('secondStep')
      : colorsStepsActived('thirdStep')

  return (
    <S.AccordionContainer key={key} ref={accordionRef} expanded={expanded} onChange={onToggle}>
      <S.AccordionHeader
        expandIcon={
          <S.CircleIcon>
            <UilArrowDown />
          </S.CircleIcon>
        }
      >
        <IconComponent
          color={color}
          fontWeight="normal"
          code={stage?.projectStage?.project?.projectCategory?.[0]?.category?.txImagePath}
          size="xsmall"
        />
        <S.AccordionTitle ml={1} color={color}>
          {title}
        </S.AccordionTitle>
      </S.AccordionHeader>
      <DividerComponent />
      <Box mt={2}>
        {stage?.projectStage?.projectStageOrientation?.map((orientation, index: number) => (
          <>
            <S.Title fontSize={20} variant="caption" color={color}>
              {orientation.txTitle}
            </S.Title>
            <S.AccordionDetailsContent key={index}>
              <S.ContentParagraphs>
                <S.Paragraph>
                  <CustomHtml htmlContent={String(orientation.txOrientationCode)} />
                </S.Paragraph>
              </S.ContentParagraphs>
            </S.AccordionDetailsContent>

            {orientation.projectStageSupportReference && orientation.projectStageSupportReference.length > 0 && (
              <>
                <DividerComponent />

                <Box my={2}>
                  <Typography variant="caption" fontSize={18} fontWeight={700} mt={5} color={color}>
                    Referências de Apoio
                  </Typography>

                  <S.ContentCards>
                    <S.PutStart>
                      <S.Arrow onClick={handlePrevClick} enabled={isPrevDisabled}>
                        <UilArrowLeft size="20" />
                      </S.Arrow>
                    </S.PutStart>
                    {orientation.projectStageSupportReference
                      .slice(currentIndex, currentIndex + itemsPerPage)
                      .map((reference, index: number) => {
                        return (
                          reference && (
                            <S.CardLink key={index} to={reference.mediaInformation.txAbsoluteUrl} target="_blank">
                              <S.CardReference
                                ref={(element) => (cardRef.current[index] = element)}
                                type={'firstStep'}
                                maxHeight={maxHeight}
                              >
                                <UilArrowUpRight size={25} color={colorsStepsActived('firstStep')} />
                                <S.TextCard variant="caption" type={'firstStep'}>
                                  {reference.txTitle}
                                </S.TextCard>
                              </S.CardReference>
                            </S.CardLink>
                          )
                        )
                      })}
                    <S.PutEnd>
                      <S.Arrow onClick={handleNextClick} enabled={isNextDisabled}>
                        <UilArrowRight size="20" />
                      </S.Arrow>
                    </S.PutEnd>
                  </S.ContentCards>
                </Box>
              </>
            )}
          </>
        ))}
      </Box>
    </S.AccordionContainer>
  )
}
