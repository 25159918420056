import { UilArrowRight, UilCheck, UilMultiply, UilTrash } from '@iconscout/react-unicons'
import { Divider } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { useGetAssessmentCount } from '~/services/Assessment'

import { ButtonComponent, StatusTextComponent } from '~/components'

import { renderStatusText } from '~/validations/renderStatusText'

import { ModalComponentBase } from '../ModalBase/Modal'
import CardNotification from './CardNotification/CardNotification'
import { DateItem, IModalGenericProps } from './ModalAction.interfaces'
import * as S from './ModalAction.styles'

export const ModalActionComponent = ({
  open,
  actionCloseModal,
  actionCofirm,
  actionCofirmRemark,
  title,
  icon,
  btnColor,
  returnBtnText,
  confirmCancelText,
  description,
  loading,
  infoWidth,
  isFullModal,
  isConfirm,
  isDesengage,
  isWarning,
  idClass,
  IdMoment,
  dates,
  isRemark,
  actionConfirmNewDate,
  ActionRemarkNewDate,
  isNotification,
  data,
}: IModalGenericProps) => {
  const { width } = useWindowDimensions()

  const { data: dataCount } = useGetAssessmentCount(idClass as number, IdMoment as number)

  const filteredItems = dates?.filter(
    (item: DateItem) =>
      item?.coMomentStatus === 'FINA' || item?.coMomentStatus === 'AVPE' || item?.coMomentStatus === 'CANC',
  )
  const isBloqued = filteredItems?.find(
    (item: DateItem) =>
      item?.coMomentStatus === 'FINA' || item?.coMomentStatus === 'AVPE' || item?.coMomentStatus === 'CANC',
  )
  return (
    <ModalComponentBase
      open={open}
      actionCloseModal={actionCloseModal}
      maxWidth={(width ?? 0) > (infoWidth ?? 0) ? '' : '350px'}
    >
      <S.Container isNotification={isNotification}>
        {isFullModal && (
          <>
            {!isNotification && (
              <>
                {isConfirm ? (
                  <S.ContentIconFull color={isConfirm ? '#E8F6FF' : '#fff7f5'}>
                    {icon ? icon : <UilTrash size={30} color="#0095FF" />}
                  </S.ContentIconFull>
                ) : (
                  <S.ContentIconFull color={isWarning ? '#fff7f5' : '#F4F7FE'}>
                    {icon ? icon : <UilTrash size={30} color="#0095FF" />}
                  </S.ContentIconFull>
                )}
              </>
            )}

            {isNotification && (
              <S.SmallView>
                <S.ContentIcon>{icon ? icon : <UilCheck size={48} color="#0095FF" />}</S.ContentIcon>
                <S.BtnClose
                  data-testid="close-notification-button"
                  isNotification={isNotification}
                  onClick={actionCloseModal}
                >
                  <UilMultiply size={16} color="#000" />
                </S.BtnClose>
              </S.SmallView>
            )}

            <S.Title>{title}</S.Title>
            <S.Description>
              {isBloqued ? 'Atenção! As seguintes aulas não serão desocupadas.' : description}
            </S.Description>

            {isConfirm && (
              <S.ContentInfoBox>
                <S.ContentInfoTitle>Você realizou:</S.ContentInfoTitle>
                <S.ContentInfoWarn>
                  <S.TextInfoWarn
                    style={{
                      backgroundColor: (dataCount?.documentationsCount ?? 0) > 0 ? '#E1FFEB' : '#FFF7F5',
                      color: (dataCount?.documentationsCount ?? 0) > 0 ? '#29CC5F' : '#D44333',
                    }}
                  >
                    {dataCount?.documentationsCount ?? 0} documentações
                  </S.TextInfoWarn>
                  <S.TextInfoWarn
                    style={{
                      backgroundColor: (dataCount?.assessmentsCount ?? 0) > 0 ? '#E1FFEB' : '#FFF7F5',
                      color: (dataCount?.assessmentsCount ?? 0) > 0 ? '#29CC5F' : '#D44333',
                    }}
                  >
                    {dataCount?.assessmentsCount ?? 0} avaliações
                  </S.TextInfoWarn>
                </S.ContentInfoWarn>
              </S.ContentInfoBox>
            )}
            {isBloqued && (
              <>
                <S.ContainerBox>
                  <Divider />
                  {filteredItems?.map((item: DateItem) => (
                    <>
                      <S.ContenBox>
                        <S.TitleWrapper>
                          <S.WrapperNumber>
                            <S.NumberBox>{item?.nuOrder}</S.NumberBox>
                          </S.WrapperNumber>
                          <S.TitleCardProject>{item?.txTitle}</S.TitleCardProject>
                        </S.TitleWrapper>
                        <StatusTextComponent
                          statusCode={item?.coMomentStatus}
                          statusText={renderStatusText(item?.coMomentStatus)}
                        />
                      </S.ContenBox>
                      <Divider />
                    </>
                  ))}
                </S.ContainerBox>
              </>
            )}
            {isNotification && (
              <S.ContainerCardNotification className="styled-scroll-s">
                {data
                  ?.filter((item) => !item.isRead)
                  ?.map((item, index) => (
                    <CardNotification
                      key={index}
                      actionConfirmNewDate={(itemNotification) =>
                        actionConfirmNewDate && actionConfirmNewDate(itemNotification)
                      }
                      ActionRemarkNewDate={(itemNotification) =>
                        ActionRemarkNewDate && ActionRemarkNewDate(itemNotification)
                      }
                      itemNotification={item}
                    />
                  ))}
              </S.ContainerCardNotification>
            )}
            <Divider />
            <S.ContentButtons>
              <ButtonComponent
                data-testid={'ConfirmBtn'}
                text={confirmCancelText}
                loading={loading}
                variant="solid"
                size={isNotification ? 'large' : 'extra-large'}
                color={btnColor}
                onClick={actionCofirm}
                fullWidth={isNotification ? true : (width ?? 0) < 700}
                iconStart={isConfirm ? <UilCheck size={25} color="#fff" /> : undefined}
                iconEnd={
                  isDesengage ? (
                    <UilArrowRight size={20} color="#fff" />
                  ) : isNotification ? (
                    <UilCheck size={24} color="#fff" />
                  ) : undefined
                }
              />
              {isRemark && (
                <ButtonComponent
                  iconEnd={<UilArrowRight size={20} />}
                  onClick={actionCofirmRemark}
                  variant="outline"
                  fontWeight="normal"
                  fullWidth={true}
                  size="large"
                  data-testid="trail-remark-button"
                  text="Realizada em outro dia"
                />
              )}
              {isRemark && (
                <ButtonComponent
                  data-testid={'ReturnBtn'}
                  text={returnBtnText}
                  variant="text"
                  size="extra-large"
                  fontWeight="normal"
                  color="normal"
                  onClick={actionCloseModal}
                  fullWidth={(width ?? 0) < 700}
                  iconEnd={
                    isDesengage ? (
                      <UilArrowRight size={20} color="#fff" />
                    ) : isNotification ? (
                      <UilCheck size={24} color="#fff" />
                    ) : undefined
                  }
                />
              )}
              {!isNotification && !isRemark && (
                <ButtonComponent
                  data-testid={'ReturnBtn'}
                  text={returnBtnText}
                  variant="text"
                  size="extra-large"
                  fontWeight="normal"
                  onClick={actionCloseModal}
                  fullWidth={(width ?? 0) < 700}
                />
              )}
            </S.ContentButtons>
          </>
        )}
        {!isFullModal && (
          <>
            <S.SmallView>
              <S.ContentIcon>{icon ? icon : <UilCheck size={25} color="#0095FF" />}</S.ContentIcon>
              <S.BtnClose data-testid="close-button" onClick={actionCloseModal}>
                <UilMultiply size={16} color="#000" />
              </S.BtnClose>
            </S.SmallView>
            <S.Title>{title}</S.Title>
          </>
        )}
      </S.Container>
    </ModalComponentBase>
  )
}
