import { useRef, useState } from 'react'

import { AppBarComponent, ContainerComponent } from '~/components'

import { StepAccordionProject } from '~/containers/private/Fund1/Planning/StepAccordionProject/StepAccordionProject'
import {
  FixedEvidencesComponent,
  HeaderPlanning,
  ProjectGeneralOrientation,
  VariableEvidencesComponent,
} from '~/containers/private/Fund2/Planning'

import { useProjectPlanningOfflinePageContext } from './context'
import * as S from './styles'

export const ProjectPlanningViewOffline = () => {
  const { fixedEvidences, txTitleLesson, projectMomentStage, txGuidance } = useProjectPlanningOfflinePageContext()

  const overallStepRef = useRef<HTMLDivElement>(null)
  const [expandedAccordions, setExpandedAccordions] = useState<Record<number, boolean>>({})
  const toggleAccordion = (index: number) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }
  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <>
          <HeaderPlanning isOffline={true} txNameLesson={txTitleLesson} />
          <S.Container>
            <S.FixedEvidencesContainer>
              <FixedEvidencesComponent fixedEvidence={fixedEvidences} isOffline={true} />
            </S.FixedEvidencesContainer>

            {projectMomentStage?.[0]?.projectStage.evidenceVariable && (
              <S.VariableEvidencesContainer>
                <VariableEvidencesComponent projectMomentStage={projectMomentStage} />
              </S.VariableEvidencesContainer>
            )}
            {txGuidance && (
              <ProjectGeneralOrientation title={txGuidance} accordionRef={overallStepRef} key={'GeneralOrientation'} />
            )}
            {projectMomentStage?.[0]?.projectStage.projectStageOrientation?.map((guidance, index) => (
              <StepAccordionProject
                key={guidance.idProjectStageOrientation}
                title={guidance.txTitle}
                expaded={expandedAccordions[index] || false}
                onToggle={() => toggleAccordion(index)}
                txGuidance={[guidance]}
                type="generalStep"
              />
            ))}
          </S.Container>
        </>
      </ContainerComponent>
    </>
  )
}
