import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilGraduationCap, UilHistory, UilInfoCircle } from '@iconscout/react-unicons'
import { Backdrop, Box, CircularProgress, Skeleton } from '@mui/material'
import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { useMarkUserMessage, useUserOptInMessage } from '~/services/Message'

import {
  AppBarComponent,
  ButtonComponent,
  ContainerComponent,
  ModalUpdateList,
  SkeletonComponent,
  StepsTabsComponent,
  ValidateDataSessionComponent,
} from '~/components'

import { HeaderComponent } from '~/containers/private/StudentDashboard'
import { HistoryComponent } from '~/containers/private/StudentDashboard/History/History'
import { OverviewByCompetenceComponent } from '~/containers/private/StudentDashboard/OverviewByCompetence/OverviewByCompetence'
import { OverviewBySkillComponent } from '~/containers/private/StudentDashboard/OverviewBySkill/OverviewBySkill'

import { formatCpf } from '~/validations/formatCpf'
import { formatPhone } from '~/validations/formatPhone'

import { useStudentDashboardPageContext } from './context'
import * as S from './styles'

export const StudentDashboardView = () => {
  const { user } = useAuth()
  const { width } = useWindowDimensions()
  const isMobile = width <= 768
  const { studentId } = useParams()
  const [activedTabNavigation, actionSetActiveTabNavigation] = useState<number>(0)
  const [activitedTabSkills, setActivedTabSkills] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const messageId = '4280a876-e454-48c5-8eea-2be8dd678f2a'
  const { data: checkMark, refetch } = useUserOptInMessage(messageId)
  const { dataDashboard, txGrade, txNameClass, student, isLoading, isLoadingStudentInfo } =
    useStudentDashboardPageContext()

  const extraData = {
    txEmail: student?.txEmail,
    txPhone: student?.txPhone,
    txCpf: student?.txCpf,
    dtBirthdate: student?.dtBirthdate,
  }

  const isResponsable = user?.role.includes('pais')
  const isDataEmpty = !dataDashboard?.idUserStudent || isLoadingStudentInfo || isLoading
  const { mutate, isLoading: loadingMutate, isSuccess } = useMarkUserMessage()

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = (change: boolean) => {
    if (change) {
      mutate(messageId)
    } else {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    if (!loadingMutate && isSuccess) {
      setIsOpen(false)
    }
    // eslint-disable-next-line
  }, [loadingMutate, isSuccess])

  useEffect(() => {
    if (checkMark) {
      refetch()
      setIsOpen(!checkMark.value)
    }
    // eslint-disable-next-line
  }, [checkMark])

  return (
    <>
      {(isLoading || isLoadingStudentInfo) && (
        <Backdrop sx={{ color: '#fff', zIndex: 999 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <AppBarComponent />

      <ContainerComponent>
        {isLoadingStudentInfo ? (
          <S.SkeletonHeader>
            <SkeletonComponent variant="circular" width={100} height={100} />
            <div>
              <SkeletonComponent variant="rounded" width={200} height={20} />
              <SkeletonComponent variant="rounded" width={100} height={10} />
            </div>
          </S.SkeletonHeader>
        ) : (
          <HeaderComponent
            username={student?.txName as string}
            studentId={Number(student?.idUser)}
            txImagePath={student?.txImagePath as string}
            txNameClass={txNameClass}
            txGrade={txGrade}
          />
        )}
        {isLoading ? (
          <S.SkeletonInfo>
            <SkeletonComponent variant="rounded" width={'90%'} height={50} />
            <SkeletonComponent variant="rounded" width={'40%'} height={50} />
            <SkeletonComponent variant="rounded" width={'100%'} height={200} />
          </S.SkeletonInfo>
        ) : (
          <>
            <S.ContentTabs>
              {extraData && isResponsable && (
                <S.ExtraDataContainer>
                  <h3>Informações Adicionais:</h3>
                  {extraData.txEmail && <p>Email: {extraData.txEmail}</p>}
                  {extraData.txPhone && <p>Telefone: {formatPhone(extraData.txPhone)}</p>}
                  {extraData.txCpf && <p>CPF: {formatCpf(extraData.txCpf)}</p>}
                  {extraData.dtBirthdate && (
                    <p>Data de nascimento: {dayjs(extraData.dtBirthdate).format('DD/MM/YYYY')}</p>
                  )}
                </S.ExtraDataContainer>
              )}
              <StepsTabsComponent
                length={2}
                activedTab={activedTabNavigation}
                steps={[
                  {
                    label: isMobile && activedTabNavigation !== 0 ? '' : 'Painel do Aluno',
                    icon: <UilGraduationCap size={24} color={activedTabNavigation === 0 ? '#FFF' : '#8C22BC'} />,
                  },
                  {
                    label: isMobile && activedTabNavigation !== 1 ? '' : 'Histórico',
                    icon: <UilHistory size={24} color={activedTabNavigation === 1 ? '#FFF' : '#8C22BC'} />,
                  },
                ]}
                actionSetActiveTab={(newActivedTab: number) => actionSetActiveTabNavigation(newActivedTab)}
                isNavigate={true}
                typeNavigation={'firstStep'}
              />

              {activedTabNavigation === 0 && !isDataEmpty && (
                <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'}>
                  <StepsTabsComponent
                    maxWidth={'300px'}
                    length={2}
                    activedTab={activitedTabSkills}
                    steps={[
                      {
                        label: 'Habilidades',
                      },
                      {
                        label: 'Competências',
                      },
                    ]}
                    actionSetActiveTab={(newActivedTab: number) => setActivedTabSkills(newActivedTab)}
                    isNavigate={true}
                    typeNavigation={'default'}
                  />
                  <ButtonComponent
                    size="small"
                    variant="text"
                    fontWeight="bold"
                    iconStart={<UilInfoCircle size={24} color="#666666" />}
                    onClick={() => handleOpenModal()}
                  />
                  {isOpen && (
                    <ModalUpdateList
                      isOpen={isOpen}
                      handleCloseModal={handleCloseModal}
                      checkMarkValue={checkMark}
                      type={'button'}
                    />
                  )}
                </Box>
              )}
            </S.ContentTabs>
            <>
              {activedTabNavigation === 0 && (
                <>
                  {isLoading || isDataEmpty === true ? (
                    <Skeleton variant="rectangular" height={200} />
                  ) : (
                    <>
                      {!isDataEmpty ? (
                        <S.Container>
                          {activitedTabSkills === 0 && (
                            <OverviewBySkillComponent competences={dataDashboard?.competences} />
                          )}
                          {activitedTabSkills === 1 && (
                            <OverviewByCompetenceComponent competences={dataDashboard?.competences} />
                          )}
                        </S.Container>
                      ) : (
                        <S.EmptyContainer>
                          <ValidateDataSessionComponent
                            screen="class"
                            title="Esse aluno ainda não possui dados"
                            description=" "
                            space={-250}
                          />
                        </S.EmptyContainer>
                      )}
                    </>
                  )}
                </>
              )}
            </>
            {activedTabNavigation === 1 && <HistoryComponent studentId={Number(studentId)} />}
          </>
        )}
      </ContainerComponent>
    </>
  )
}
