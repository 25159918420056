import { useNavigate } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'
import { Box, Tooltip } from '@mui/material'

import { TrailList } from '~/services/Trails/types'

import { ButtonComponent } from '~/components'

import theme from '~/styles/theme'

import { IActiveMomentCardComponentProps, UrlMap } from './ActiveMomentCard.interfaces'
import * as S from './ActiveMomentCard.styles'

export const ActiveMomentCardComponent = ({
  color,
  lessons,
  thisLesson,
  idLessonMoment,
  idLesson,
  lessonActivity,
  navigationState,
  txTrackTitle,
  idClass,
  isOffline = 'false',
  idMomentTrack,
  coStage,
  handleNavigation,
  status,
}: IActiveMomentCardComponentProps) => {
  const navigate = useNavigate()
  const lessonArray = Array.from({ length: lessons || 0 }, (_, index) => index + 1)
  switch (color) {
    case 'AGEN':
      color = theme.colors.midDark
      break
    case 'AUPL':
      color = theme.colors.primaryPure
      break
    case 'AVPE':
      color = theme.colors.warningPure
      break
    case 'FINA':
      color = theme.colors.positivePure
      break
    case 'PEND':
      color = theme.colors.actionPure
      break
    case 'AUPE':
      color = theme.colors.negativePure
      break
    case 'CANC':
      color = theme.colors.lowLight
      break
  }

  const urlMap: UrlMap = {
    IN: '/trail/:idTrack/occupied/:idClass',
    F1: '/trail/:idTrack/occupied/:idClass',
    M2: '/trail/:idTrack/occupied/:idClass',
    F2: '/trail/:idTrack/project/:idClass',
    EM: '/trail/:idTrack/project/:idClass',
    M3: '/trail/:idTrack/project/:idClass',
    M4: '/trail/:idTrack/project/:idClass',
  }

  const validateTrailRouter = (coStage: string, idTrack: string, idClass: number) => {
    const url = urlMap[coStage] || ''
    if (url) {
      const formattedUrl = url.replace(':idTrack', idTrack).replace(':idClass', idClass.toString())
      navigate(formattedUrl, { state: { from: window.location.pathname + window.location.search } })
    }

    return url
  }

  const TrailTooltip = ({
    lessonOrder,
    children,
  }: {
    trail: TrailList
    children: React.ReactElement
    lessonOrder: number
  }) => {
    const renderTrail = () => {
      return (
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <S.InfoText>Essa é a {lessonOrder}ª aula da trilha:</S.InfoText>

          <S.TrailTitle>{txTrackTitle ? txTrackTitle : ''}</S.TrailTitle>

          <S.OrderScheduleBox>
            <ButtonComponent
              onClick={(event) => {
                event.stopPropagation(), validateTrailRouter(coStage, idMomentTrack, idClass)
              }}
              size="small"
              data-testid={`button-access-track`}
              variant="outline"
              fontWeight="normal"
              text="Acessar Trilha"
              iconEnd={<UilArrowRight size={16} color={theme.colors.actionPure} />}
            />
          </S.OrderScheduleBox>
        </Box>
      )
    }
    return (
      <S.TooltipContainerHover>
        <S.TooltipContainer>
          <Tooltip
            title={renderTrail()}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '12px',
                  padding: '16px',
                  fontWeight: 'bold',
                  backgroundColor: theme.colors.highPure,
                  width: '334px',
                  top: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
                  zIndex: '99999999',
                },
              },
            }}
            placement={'bottom-start'}
          >
            {children}
          </Tooltip>
        </S.TooltipContainer>
      </S.TooltipContainerHover>
    )
  }

  return (
    <>
      {isOffline === 'false' && (
        <TrailTooltip trail={lessonArray} lessonOrder={thisLesson} txTrackTitle={txTrackTitle}>
          <S.ContainerDivider>
            <>
              {lessonArray?.map((lesson) => (
                <S.RowNumbers
                  data-testid={`lesson-${lesson}`}
                  key={lesson}
                  color={color}
                  isHighlighted={lesson === thisLesson}
                >
                  {lesson}
                </S.RowNumbers>
              ))}
            </>
          </S.ContainerDivider>
        </TrailTooltip>
      )}

      {isOffline === 'true' && (
        <S.ContainerDivider>
          <>
            {lessonArray?.map((lesson) => (
              <S.RowNumbers key={lesson} color={color} isHighlighted={lesson === thisLesson}>
                {lesson}
              </S.RowNumbers>
            ))}
          </>
        </S.ContainerDivider>
      )}
    </>
  )
}
