import { useEffect, useState } from 'react'

import { CircularProgress, Divider } from '@mui/material'

import { AppBarComponent, ContainerComponent } from '~/components'
import { AlertAssessment } from '~/components/Forms/Assessment/Assessment.styles'

import { HeaderComponent } from '~/containers/private/Fund1/LessonAssessment'

import { useAssessmentPageContext } from './context'
import LessonForm from './Forms/LessonForm'
import ProjectForm from './Forms/ProjectForm'

export const LessonAssessmentView = () => {
  const {
    tabActived,
    handleActiveNewTab,
    mutateAssessment,
    mutateAssessmentBatch,
    isLoadingAssessment,
    stage,
    idActivity,
    fixedPayload,
    variablePayload,
    firstEvidence,
    lessonStep,
    status,
    projectId,
    isSameUser,
    students,
    isLoading,
    mutateAssessmentProject,
    mutateAssessmentBatchProject,
    isLoadingAssessmentProject,
    isLoadingAssessmentBatch,
    isLoadingAssessmentBatchProject,
    isProject,
    idLessonMoment,
    idProjectStage,
    idClass,
    isClassInStatus,
    classTitle,
    path,
    isFetching,
  } = useAssessmentPageContext()

  const [anyLoading, setAnyLoading] = useState(false)
  const [afterSendLoading, setAfterSendLoading] = useState(false)

  useEffect(() => {
    const isLoading =
      isLoadingAssessment || isLoadingAssessmentProject || isLoadingAssessmentBatch || isLoadingAssessmentBatchProject

    if (anyLoading && !isLoading) {
      setTimeout(() => {
        setAfterSendLoading(true)
      }, 200)
      setTimeout(() => setAfterSendLoading(false), 1200)
    }
    setAnyLoading(isLoading)
  }, [
    isLoadingAssessment,
    isLoadingAssessmentProject,
    isLoadingAssessmentBatch,
    isLoadingAssessmentBatchProject,
    anyLoading,
  ])

  return (
    <>
      {anyLoading && (
        <AlertAssessment
          iconMapping={{
            info: <CircularProgress color="inherit" size={16} />,
          }}
          severity={'info'}
        >
          Estamos processando a sua avaliação.
        </AlertAssessment>
      )}
      {afterSendLoading && (
        <AlertAssessment
          iconMapping={{
            info: <CircularProgress color="inherit" size={16} />,
          }}
          severity={'success'}
        >
          Avaliação salva com sucesso.
        </AlertAssessment>
      )}
      <AppBarComponent />
      <ContainerComponent>
        <HeaderComponent
          title={classTitle}
          path={path}
          statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status as string)}
        />

        {!isProject ? (
          <>
            <LessonForm
              idClass={idClass}
              loading={false}
              lessonStep={lessonStep}
              tabActived={tabActived}
              handleActiveNewTab={handleActiveNewTab}
              mutateAssessment={mutateAssessment}
              isFinished={['FINA'].includes(status as string)}
              mutateAssessmentBatch={mutateAssessmentBatch}
              status={status}
              idLessonMoment={idLessonMoment}
              firstEvidence={firstEvidence}
              isClassInStatus={isClassInStatus}
            />
          </>
        ) : (
          <>
            <ProjectForm
              stages={stage}
              idClass={idClass}
              isSameUser={isSameUser}
              status={status}
              isLoading={isLoading}
              idProject={projectId}
              students={students}
              tabActived={tabActived}
              handleActiveNewTab={handleActiveNewTab}
              mutateAssessment={mutateAssessmentProject}
              mutateAssessmentBatch={mutateAssessmentBatchProject}
              isClassInStatus={isClassInStatus}
            />

            <Divider />
          </>
        )}
      </ContainerComponent>
    </>
  )
}
