import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { GET_CLASS_INFOS_BY_CLASS_ID } from '~/schemas'

import { GetGradesService } from '~/pages/private/Classes/ListClasses/schemas.queries'
import { GET_PROJECT_BY_ID } from '~/pages/private/Fund2/Planning/queries'

import { GetGradeListResponse, IDataClassInfosReponse } from './interface'

export const useGQLServiceGetClassInfosByClassID = (classId: number, showDisabledClasses: boolean) =>
  useQueryGraphQLClient<IDataClassInfosReponse>('network', 'get-class-infos-by-class-ID', GET_CLASS_INFOS_BY_CLASS_ID, {
    classId,
    showDisabledClasses,
  })

export const useGQLProjectID = (projectId: number) =>
  useQueryGraphQLClient<any>('track', 'get-project-by-id', GET_PROJECT_BY_ID, {
    projectId,
  })

export const useGQLServiceGetGradeList = () =>
  useQueryGraphQLClient<GetGradeListResponse>('track', 'get-Grades-list', GetGradesService, {})
