import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TablePagination } from '@mui/material'

import { useSnackbar } from '~/_context/Snackbar'

import { useStudents } from '~/services/Students'

import { AppBarComponent, ContainerComponent, SkeletonComponent, ValidateDataSessionComponent } from '~/components'

import { CardStudentComponent, HeaderComponent } from '~/containers/private/Students'

import { useStudentsPageContext } from './context'
import * as S from './styles'

export const StudentsView = () => {
  const { isLoading: loadingClasses, data: dataClasses } = useStudentsPageContext()
  const navigate = useNavigate()
  const [studentsState, setStudentsState] = useState<any>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [classListParams, setClassListParams] = useState<number[]>([])
  const [classes, setClasses] = useState<any[]>([])
  const [stateNameInput, setStateNameInput] = useState<string>('')
  const [orderBy, setOrderBy] = useState<number>(0)
  const { showSnackbarError } = useSnackbar()

  const {
    data: dataStudents,
    refetch: refetchStudents,
    isLoading: loadingStudents,
    isError: errorStudents,
    isRefetching,
  } = useStudents(stateNameInput, page + 1, rowsPerPage, orderBy, classListParams)

  useEffect(() => {
    if (!loadingStudents && errorStudents) {
      showSnackbarError(errorStudents?.response?.data?.message || 'Ocorreu um erro.')
    }
  }, [errorStudents, loadingStudents, showSnackbarError])

  useEffect(() => {
    if (!loadingStudents && dataStudents) {
      setStudentsState(dataStudents?.data)
    }
  }, [loadingStudents, dataStudents])

  useEffect(() => {
    if (!dataStudents?.data?.length) return

    setStudentsState(dataStudents?.data)
  }, [dataStudents])

  useEffect(() => {
    const concatClasses = dataClasses?.classByTutor?.items.map((item: any) => ({
      className: `${item?.schoolGrade?.grade?.txGrade} - ${item?.txName}`,
      coGrade: item.txName,
      idClass: item?.idClass,
      checked: false,
    }))

    setClasses(concatClasses)
  }, [dataClasses?.classByTutor])

  const handleFilterByClassName = (e: any) => {
    const list: any[] = []

    dataClasses?.classByTutor?.items.map((item: any) => {
      const format = `${item?.schoolGrade?.grade?.txGrade} - ${item?.txName}`

      const exists = format?.toLowerCase()?.includes(e.target.value.toLowerCase())

      if (exists) {
        const objClass = {
          idClass: item?.idClass,
          className: format,
          checked: false,
        }
        list.push(objClass)
      }
    })
    setClasses(list)
  }

  useEffect(() => {
    const value = dataStudents?.headers['x-pagination']?.length
      ? JSON.parse(dataStudents?.headers['x-pagination'])?.currentPage
      : ''

    if (Number(value) !== Number(page + 1)) {
      refetchStudents().then()
    }
  }, [dataStudents?.headers, page, refetchStudents])

  useEffect(() => {
    refetchStudents().then()
  }, [refetchStudents, orderBy, stateNameInput])

  useEffect(() => {
    setPage(0)
    if (classListParams.length >= 0) {
      refetchStudents().then()
    }
  }, [refetchStudents, stateNameInput, rowsPerPage, orderBy, classListParams])

  const handleChangeClassesSelected = (e: any, idClass: number) => {
    const objToUpdate = classes.filter((c) => c.idClass === idClass)[0]
    const isChecked = e.target.checked
    if (isChecked) {
      setClassListParams((prevSelectedClasses: any) => [...prevSelectedClasses, idClass])
    } else {
      setClassListParams((prevSelectedClasses: any) =>
        prevSelectedClasses.filter((classId: number) => classId !== idClass),
      )
    }
    objToUpdate.checked = isChecked

    const updatedClasses = classes.map((item) => {
      if (item.idClass === objToUpdate.idClass) {
        return { ...item, checked: objToUpdate.checked }
      }
      return item
    })

    setClasses(updatedClasses)
  }

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          isLoadingFilterClasses={loadingClasses || isRefetching}
          classes={classes}
          setClasses={setClasses}
          handleInputFilter={(e: any) => setStateNameInput(e.target.value ?? '')}
          handleOrderByStudentName={() => setOrderBy(3)}
          handleOrderByClassName={() => setOrderBy(1)}
          handleOrderByShift={() => setOrderBy(2)}
          handleFilterByClassName={handleFilterByClassName}
          orderActived={orderBy}
          handleChangeClassesSelected={handleChangeClassesSelected}
        />

        {loadingClasses || isRefetching ? (
          <S.ContentCards>
            {[...Array(8)].map((_, index) => (
              <>
                <S.ContentSkeleton>
                  <S.BoxAvatar>
                    <SkeletonComponent key={index} variant="circular" width={40} height={40} />
                  </S.BoxAvatar>
                  <S.ColumnFlex>
                    <SkeletonComponent key={index} width={120} height={15} />
                    <SkeletonComponent key={index} width={120} height={10} />
                  </S.ColumnFlex>
                </S.ContentSkeleton>
              </>
            ))}
          </S.ContentCards>
        ) : !loadingClasses && studentsState?.length ? (
          <S.ContentCards>
            {studentsState?.map((student: any, index: number) => {
              return (
                <CardStudentComponent
                  key={index}
                  index={index}
                  student={student}
                  actionChangePicture={() => {
                    navigate(`/students/${student?.idStudentUser}`)
                  }}
                />
              )
            })}

            <S.ContentPagination>
              <TablePagination
                component="div"
                count={JSON.parse(dataStudents?.headers['x-pagination'])?.totalCount}
                rowsPerPageOptions={[2, 5, 10, 15, 20]}
                page={page}
                labelRowsPerPage={''}
                labelDisplayedRows={({ from, to, count }) => {
                  const validFrom = typeof from === 'number' && !isNaN(from) ? from : 1
                  const validTo = typeof to === 'number' && !isNaN(to) ? to : 1
                  const validCount = typeof count === 'number' && !isNaN(count) ? count : 0
                  const finalFrom = Math.min(validFrom, validTo)
                  const finalTo = Math.min(validTo, validCount)
                  return `Mostrando ${finalFrom}-${finalTo} de ${validCount}`
                }}
                onPageChange={(_, newPage: number) => {
                  setPage(newPage)
                }}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setRowsPerPage(Number(event.target.value))
                }
              />
            </S.ContentPagination>
          </S.ContentCards>
        ) : (
          <S.ContentEmoji>
            <ValidateDataSessionComponent
              screen="students"
              title="Nenhum aluno encontrado."
              description=""
              space={100}
            />
          </S.ContentEmoji>
        )}
      </ContainerComponent>
    </>
  )
}
