import { pdfjs } from 'react-pdf'

import { UilFileDownloadAlt } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import { pdf } from '@react-pdf/renderer'

import { ButtonComponent, QuickNavigationComponent } from '~/components'

import { PDFDocument } from '../../../../../pages/private/Fund1/Planning/DocumentPDF/PDFDocument'
import * as S from './Header.styles'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IHeaderPlanning {
  handleSetActiveTab: (newActivedTab: number) => void
  activedTab?: number
  txNameLesson: string
  guidance?: string
  steps?: any
  evidencesByActivityOnStepTwo?: any
  fixedEvidences?: any
  isOffline?: boolean
  LessonOrder?: string
  lessonOrientations: any
  trackTitle?: string
  status?: string
}

export const HeaderPlanning = ({
  txNameLesson,
  guidance,
  steps,
  evidencesByActivityOnStepTwo,
  fixedEvidences,
  isOffline,
  status,
  LessonOrder,
  trackTitle,
  lessonOrientations,
}: IHeaderPlanning) => {
  const generatePdfDocument = async (fileName: string) => {
    const blob = await pdf(
      <PDFDocument
        LessonOrder={LessonOrder}
        trackTitle={trackTitle}
        lessonOrientations={lessonOrientations}
        guidance={guidance}
        steps={steps}
        txTitleLesson={txNameLesson}
        evidencesByActivityOnStepTwo={evidencesByActivityOnStepTwo}
        fixedEvidences={fixedEvidences}
      />,
    ).toBlob()
    const data = (window.webkitURL || window.URL).createObjectURL(blob)
    const link = document.createElement('a')
    link.href = data
    link.download = fileName?.replace(/"/g, '')
    link.click()

    setTimeout(function () {
      ;(window.webkitURL || window.URL).revokeObjectURL(data)
    }, 100)
  }
  return (
    <S.HeaderContainer id={'header'}>
      <S.ContainerTitle>
        <QuickNavigationComponent
          label={txNameLesson}
          stepActived="planning"
          statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status ?? '')}
          status={status}
        />
        <S.ContentBack>
          <S.Title>Orientações da Aula</S.Title>
          {!isOffline && (
            <Tooltip title={'Download em PDF'} placement="top">
              <div>
                <ButtonComponent
                  variant="outline"
                  iconEnd={<UilFileDownloadAlt size="20" />}
                  data-testid="download-pdf-button"
                  size="small"
                  fontWeight={'normal'}
                  fullWidth={false}
                  onClick={() => generatePdfDocument('planejamento')}
                  text="Download"
                />
              </div>
            </Tooltip>
          )}
        </S.ContentBack>
      </S.ContainerTitle>
    </S.HeaderContainer>
  )
}
