import { createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useLessonByIdLessonMomentQuery, useLessonByIdPendingQuery } from '~/queries/autogenerate/hooks'

import { graphqlRequestClient } from '~/server/GraphQLClient'

import { useServiceGetLesson } from '~/services/Lesson'

import { ILessonPlanningPageContextProps, ILessonPlanningPageProviderProps } from './interface'

const LessonPlanningPageContext = createContext<ILessonPlanningPageContextProps>({
  isLoading: false,
  evidencesByActivityOnStepTwo: {},
  fixedEvidences: {},
  guidance: '',
  steps: {},
  txTitleLesson: '',
  status: '',
})

const LessonPlanningPageProvider = ({ children }: ILessonPlanningPageProviderProps) => {
  const { idLesson, idLessonMoment } = useParams()
  const { data: dataLesson } = useServiceGetLesson(Number(idLessonMoment), { enabled: !!idLessonMoment })
  const status = dataLesson?.momentStatus?.coMomentStatus as string
  const LessonOrder = dataLesson?.lesson?.lessonTrackGroupNuOrder
  const trackTitle = dataLesson?.lesson?.txTrackTitle

  const {
    data: dataWithIdLessonMoment,
    refetch: refetchWithIdLessonMoment,
    isLoading: isLoadingWithIdLessonMoment,
  } = useLessonByIdLessonMomentQuery(
    graphqlRequestClient('track'),
    {
      idLessonMoment: Number(idLessonMoment),
      idLesson: Number(idLesson),
    },
    { enabled: false },
  )

  const {
    data: dataWithoutIdLessonMoment,
    refetch: refetchWithoutIdLessonMoment,
    isLoading: isLoadingWithoutIdLessonMoment,
  } = useLessonByIdPendingQuery(
    graphqlRequestClient('track'),
    {
      idLesson: Number(idLesson),
    },
    { enabled: false },
  )

  useEffect(() => {
    if (!idLessonMoment) {
      refetchWithoutIdLessonMoment().then()
    } else {
      refetchWithIdLessonMoment().then()
    }
  }, [refetchWithoutIdLessonMoment, refetchWithIdLessonMoment, idLessonMoment])

  const data = useMemo(() => {
    return dataWithIdLessonMoment || dataWithoutIdLessonMoment
  }, [dataWithoutIdLessonMoment, dataWithIdLessonMoment])

  const fixedEvidences = data?.lessonById?.[0]?.lessonStep?.map((step) => {
    return {
      idLessonStep: step?.idLessonStep,
      nuOrder: step?.nuOrder,
      lessonActivityEvidence: step?.lessonActivity?.[0]?.lessonActivityEvidence?.find(
        (item: any) => item.coEvidenceType === 'FIX',
      )?.evidenceGrade,
    }
  })

  const guidance = data?.lessonById?.[0]?.txGuidance
  const evidencesByActivityOnStepTwo = data?.lessonById?.[0]?.lessonStep?.[1]?.lessonActivity
  const steps = data?.lessonById?.[0]?.lessonStep

  const txTitleLesson = data?.lessonById?.[0]?.txTitle

  const lessonPlanningPageProviderValues = useMemo(() => {
    return {
      isLoading: isLoadingWithoutIdLessonMoment && isLoadingWithIdLessonMoment,
      evidencesByActivityOnStepTwo,
      fixedEvidences,
      guidance: guidance || '',
      steps,
      status,
      txTitleLesson: txTitleLesson || '',
      LessonOrder,
      trackTitle,
    }
  }, [
    status,
    isLoadingWithIdLessonMoment,
    isLoadingWithoutIdLessonMoment,
    evidencesByActivityOnStepTwo,
    fixedEvidences,
    guidance,
    steps,
    txTitleLesson,
    LessonOrder,
    trackTitle,
  ])

  return (
    <LessonPlanningPageContext.Provider value={lessonPlanningPageProviderValues}>
      {children}
    </LessonPlanningPageContext.Provider>
  )
}

const useLessonPlanningPageContext = () => useContext(LessonPlanningPageContext)

export { LessonPlanningPageProvider, useLessonPlanningPageContext }
