import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const Container = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#F9FAFC',
  zIndex: 1,
  marginBottom: theme.spacing.md,
}))

export const Content = styledMUI(Box)(() => ({
  height: 'auto',
  width: '923px',
  backgroundColor: '#fff',
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 900px)': {
    width: '100%',
  },
}))
