import { useRef, useState } from 'react'

import { AppBarComponent, ContainerComponent } from '~/components'

import { StepAccordionProject } from '~/containers/private/Fund1/Planning/StepAccordionProject/StepAccordionProject'
import {
  FixedEvidencesComponent,
  HeaderPlanning,
  ProjectGeneralOrientation,
  SkeletonLoading,
  VariableEvidencesComponent,
} from '~/containers/private/Fund2/Planning'

import { useProjectPlanningPageContext } from '~/pages/private/Fund2/Planning/context'

import * as S from './styles'

export const ProjectPlanningView = () => {
  const {
    projectMomentStage,
    isLoading,
    titleNuOrder,
    fixedEvidence,
    txTitleLesson,
    txOrientation,
    status,
    stageOrientations,
  } = useProjectPlanningPageContext()

  const overallStepRef = useRef<HTMLDivElement>(null)
  const [activedTab, setActivedTab] = useState(0)
  const hasVariableEvidence = false
  const [expandedAccordions, setExpandedAccordions] = useState<Record<number, boolean>>({})

  const toggleAccordion = (index: number) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }
  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            {projectMomentStage && (
              <HeaderPlanning
                status={status}
                txNameLesson={String(titleNuOrder)}
                activedTab={activedTab}
                stages={projectMomentStage}
                stageOrientations={stageOrientations}
                txTitleLesson={txTitleLesson}
                txOrientation={txOrientation}
              />
            )}
            <S.Container>
              <S.FixedEvidencesContainer>
                <FixedEvidencesComponent fixedEvidence={fixedEvidence} />
              </S.FixedEvidencesContainer>

              {(hasVariableEvidence?.length ?? 0) > 0 && (
                <S.VariableEvidencesContainer>
                  <VariableEvidencesComponent projectMomentStage={projectMomentStage} />
                </S.VariableEvidencesContainer>
              )}

              {txOrientation && (
                <ProjectGeneralOrientation
                  title={txOrientation}
                  accordionRef={overallStepRef}
                  key={'GeneralOrientation'}
                />
              )}

              {stageOrientations?.length > 0 &&
                stageOrientations?.map((guidance, index) => (
                  <StepAccordionProject
                    key={guidance.idProjectStageOrientation}
                    title={guidance.txTitle}
                    expaded={expandedAccordions[index] || false}
                    onToggle={() => toggleAccordion(index)}
                    txGuidance={[guidance]}
                    type="generalStep"
                  />
                ))}
            </S.Container>
          </>
        )}
      </ContainerComponent>
    </>
  )
}
