import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { UilArrowLeft } from '@iconscout/react-unicons'
import { gaEvents } from '~/events'

import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import { IBackNavigationComponentProps } from './BackNavigation.interfaces'
import * as S from './BackNavigation.styles'

export const BackNavigationComponent = ({
  coMomentStatus,
  label,
  path,
  icon,
  tooltipText,
  name = 'Voltar',
  small,
}: IBackNavigationComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pathState, setPathState] = useState<string>('')
  const [coMomentStatusState, setCoMomentStatusState] = useState<string>('')

  useEffect(() => {
    if (path) {
      setPathState(path)
    }
    if (coMomentStatus) {
      setCoMomentStatusState(coMomentStatus)
    }
  }, [path, coMomentStatus])

  useEffect(() => {
    const parentPaths = [
      '/home',
      '/schedule',
      '/schedule/week',
      '/schedule/month',
      '/trail',
      '/class',
      '/trail-details',
    ]
    if (location.state?.from) {
      const from = location.state.from
      const isParentPage = parentPaths.some((parentPath) => from.startsWith(parentPath))
      if (isParentPage && from !== location.pathname) {
        localStorage.setItem('@LEKTO:Navigation', from)
      } else {
        localStorage.removeItem('@LEKTO:Navigation')
      }
    }
  }, [location.pathname, location.state])

  const goBack = () => {
    const previousParent = localStorage.getItem('@LEKTO:Navigation')
    if (pathState.includes('/class-details')) {
      gaEvents.eventBackNavigationButton()
    }

    if (coMomentStatusState.length > 0 && !previousParent) {
      navigate(-1)
      return
    }

    if (coMomentStatusState.length > 1 && !previousParent) {
      navigate(`/home?coMomentStatus=${coMomentStatus}`)
      return
    }

    if (pathState.length > 1) {
      if (previousParent && previousParent.includes('/trail/')) {
        navigate(-1)
        return
      } else if (previousParent && previousParent.includes('/home')) {
        navigate(pathState)
        return
      } else {
        navigate(pathState)
        return
      }
    }

    if (previousParent) {
      navigate(previousParent)
      return
    }
    navigate(-1)
  }

  return (
    <S.Container>
      {tooltipText ? (
        <DSTooltip text={tooltipText}>
          <S.ButtonIcon data-testid="back-navigation-button" onClick={goBack}>
            {icon ? icon : <UilArrowLeft size={20} color="#0095ff" />}
          </S.ButtonIcon>
        </DSTooltip>
      ) : (
        <>
          {small ? (
            <S.ButtonIcon data-testid="back-navigation-button" onClick={goBack}>
              {icon ? icon : <UilArrowLeft size={20} />}
            </S.ButtonIcon>
          ) : (
            <S.ButtonIconFull data-testid="back-navigation-button" onClick={goBack}>
              {icon ? icon : <UilArrowLeft size={20} />}
              <S.TextLabel>{!label && name}</S.TextLabel>
            </S.ButtonIconFull>
          )}
        </>
      )}

      <DSTooltip text={label as string}>
        <S.TitleLabel>{label}</S.TitleLabel>
      </DSTooltip>
    </S.Container>
  )
}
