import { UilMultiply, UilBookmark } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import { ModalComponent } from '~/components'

import { IModalBNCCComponentProps } from './ModalBNCC.interfaces'
import * as S from './ModalBNCC.styles'

export const ModalBNCCComponent = ({ open, actionCloseModal, txGuidanceBNCC }: IModalBNCCComponentProps) => {
  return (
    <ModalComponent open={open} actionCloseModal={actionCloseModal}>
      <S.Container>
        <S.WrapperTitle>
          <S.ContainertTitle>
            <S.ContentIcon>
              <UilBookmark size={24} color="#0095FF" />
            </S.ContentIcon>
            <S.Title variant="h1">Integração com o currículo</S.Title>
          </S.ContainertTitle>

          <S.ButtonClose onClick={actionCloseModal}>{<UilMultiply size={15} color="#000" />}</S.ButtonClose>
        </S.WrapperTitle>
        <S.Divider />

        <S.ContentDescription className="styled-scroll-s">
          <S.Description variant="caption">{parse(String(txGuidanceBNCC))}</S.Description>
        </S.ContentDescription>
      </S.Container>
    </ModalComponent>
  )
}
