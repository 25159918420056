import { Fragment } from 'react'

import parse from 'html-react-parser'

import { AppBarComponent, ContainerComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Fund2/ProjectBNCCRelations'

import { useLessonBNCCRelationsProjectPageContext } from './context'
import * as S from './styles'

export const LessonBNCCRelationsView = () => {
  const { projects } = useLessonBNCCRelationsProjectPageContext()

  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <HeaderComponent loading={false} isOffline title="Integração com o currículo" />

        <Fragment>
          <S.ContentParagraph>
            <S.TextParagraph>{parse(String(projects?.[0]?.projectStage?.txGuidanceBncc))}</S.TextParagraph>
          </S.ContentParagraph>
        </Fragment>
      </ContainerComponent>
    </>
  )
}
