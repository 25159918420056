import { useState } from 'react'

import { UilBookReader, UilMultiply } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ButtonComponent, ModalComponent } from '~/components'
import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import * as S from './PedagogicalPractices.styles'
import pedagogicalPractices from './PedagogicalPracticesData.json'

interface PedagogicalPracticesProps {
  coGrade?: string
  schoolGrade?: string
}
export const PedagogicalPracticesComponent = ({ coGrade, schoolGrade }: PedagogicalPracticesProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const gradesHighSchool = ['EMA1', 'EMA2', 'EMA3']
  const [showPedagogicalPracticesModal, setShowPedagogicalPracticesModal] = useState<boolean>(false)

  const handleCloseModal = () => {
    setShowPedagogicalPracticesModal(false)
  }
  return (
    <>
      {isMobile ? (
        <DSTooltip text="Conheça a Prática Pedagógica">
          <S.ButtonIcon onClick={() => setShowPedagogicalPracticesModal(true)}>
            <UilBookReader size={16} color="#0095ff" />
          </S.ButtonIcon>
        </DSTooltip>
      ) : (
        <ButtonComponent
          onClick={() => setShowPedagogicalPracticesModal(true)}
          data-testid={'ExplorePedagogicalPractice'}
          text="Conheça a Prática Pedagógica"
          variant="outline"
          size="small"
          iconEnd={<UilBookReader size="18" />}
          fontWeight="normal"
        />
      )}

      {showPedagogicalPracticesModal && (
        <ModalComponent open={showPedagogicalPracticesModal} actionCloseModal={handleCloseModal}>
          <S.Container>
            <S.HeaderContainer>
              <S.WrapperTitle>
                <S.WrapperIcon>
                  <UilBookReader size={24} color="#0095ff" />
                </S.WrapperIcon>
                <S.Title variant="h1">
                  Conheça a Prática Pedagógica {coGrade && gradesHighSchool.includes(coGrade) ? 'da' : 'do'}{' '}
                  {schoolGrade}
                </S.Title>
              </S.WrapperTitle>

              <S.ButtonClose onClick={handleCloseModal}>{<UilMultiply size={16} color="#000" />}</S.ButtonClose>
            </S.HeaderContainer>

            <S.Content>
              {pedagogicalPractices.map((practice, index) => {
                if (coGrade && practice.grades.includes(coGrade)) {
                  return (
                    <S.ModalContentContainer key={`${practice.title}-${index}`}>
                      <S.ContentTitle>{practice.title}</S.ContentTitle>
                      <S.ContentSection>
                        <S.SectionTitle>O que fazem?</S.SectionTitle>
                        <S.Description variant="body1">{parse(String(practice.firstSegment))}</S.Description>
                      </S.ContentSection>

                      <S.ContentSection>
                        <S.SectionTitle>O que aprendem?</S.SectionTitle>
                        <S.Description variant="body1">{parse(String(practice.secondSegment))}</S.Description>
                      </S.ContentSection>

                      <S.ContentSection>
                        <S.SectionTitle>Como ela se estrutura?</S.SectionTitle>
                        <S.Description variant="body1">{parse(String(practice.thirdSegment))}</S.Description>
                      </S.ContentSection>
                    </S.ModalContentContainer>
                  )
                }
                return
              })}
            </S.Content>
          </S.Container>
        </ModalComponent>
      )}
    </>
  )
}
