import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const AccordionContainer = styledMUI(Box)<{ large: number }>(({ large }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  cursor: large ? 'pointer' : 'auto',
  padding: '12px 24px 0 24px',
}))

export const Paragraph = styledMUI(Box)<{ fontSize?: string }>(({ fontSize }) => ({
  textAlign: 'left',
  fontStyle: 'normal',
  fontWeight: 400,
  wordBreak: 'break-word',
  fontSize: fontSize ? fontSize : '16px',
  lineHeight: '150%',
  color: '#000',
  padding: '0 24px',
}))

export const ContentAction = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  padding: '12px 24px',
}))

export const TextAction = styledMUI(Typography)(() => ({
  marginLeft: '2px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
  color: theme.colors.actionPure,
  transition: 'all 0.5s',

  '&:hover': {
    color: theme.colors.actionDark,
  },
}))
