import { UilEdit, UilNotes } from '@iconscout/react-unicons'
import { gaEvents } from '~/events'

import { ButtonComponent } from '~/components'

import * as S from '../ModalDocumentation.styles'

interface DocumentButtonsProps {
  variant: 'floater' | 'fixed' | 'icon'
  handleShowDocumentModal: (value: boolean) => void
  showDocumentModal: boolean
}

export const Buttons = ({ variant, showDocumentModal, handleShowDocumentModal }: DocumentButtonsProps) => {
  return (
    <>
      {variant == 'floater' && (
        <S.DocumentBtnFloater
          onClick={() => {
            gaEvents.eventOpenFloaterDocumentationButton()
            handleShowDocumentModal(true)
          }}
        >
          <ButtonComponent
            variant="solid"
            size="large"
            iconEnd={<UilNotes size="20" />}
            text="Documentar"
            fontWeight="bold"
            data-testid="floater-document-button"
          />
        </S.DocumentBtnFloater>
      )}

      {variant == 'fixed' && (
        <ButtonComponent
          variant="outline"
          onClick={() => handleShowDocumentModal(!showDocumentModal)}
          size="medium"
          fullWidth={true}
          iconEnd={<UilNotes size="20" />}
          text="Documentar"
          data-testid="fixed-document-button"
        />
      )}

      {variant == 'icon' && <UilEdit size={18} onClick={() => handleShowDocumentModal(!showDocumentModal)} />}
    </>
  )
}
