import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilBookReader, UilFileInfoAlt, UilNotes, UilStar } from '@iconscout/react-unicons'
import { useTheme } from 'styled-components'

import { BackNavigationComponent } from '~/components'

import { INavigationStateProps, IQuickNavigationComponentProps } from './QuickNavigation.interfaces'
import * as S from './QuickNavigation.styles'

export const QuickNavigationComponent = ({
  label,
  stepActived,
  idLessonActivity,
  statusBlock,
  isOffline = false,
  status,
}: IQuickNavigationComponentProps) => {
  const { idLessonMoment, idLesson, projectId, idClass } = useParams()
  const previousParent = localStorage.getItem('@LEKTO:Navigation')
  const theme = useTheme()

  const addOffline = (to: string) => {
    if (isOffline) {
      return `/offline${to}`
    }
    return to
  }

  const [navigationState, setNavigationState] = useState<INavigationStateProps>({
    planning: false,
    documentations: false,
    assessment: false,
    materials: false,
    BNCCRelations: false,
  })

  const handleNavigation = useCallback(
    (routeActive: string) => {
      const newNavigationState: any = new Object({})

      for (const key of Object.keys(navigationState)) {
        if (key === routeActive) {
          newNavigationState[routeActive as keyof INavigationStateProps] = true
        } else {
          newNavigationState[key as keyof INavigationStateProps] = false
        }
      }

      setNavigationState(newNavigationState)
    },
    [navigationState],
  )

  useEffect(() => {
    if (stepActived) {
      handleNavigation(stepActived)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepActived])

  const checkPath = () => {
    if (projectId || idLessonMoment) {
      return addOffline(
        projectId
          ? `${isOffline ? '/' : `/class/${idClass}/`}project/${projectId}`
          : `${isOffline ? '/' : `/class/${idClass}/`}lesson/${idLessonMoment}`,
      )
    } else if (previousParent) {
      return `${previousParent}`
    } else {
      return '/home'
    }
  }

  const path = checkPath()

  return (
    <S.Container>
      <BackNavigationComponent label={label} tooltipText={'Voltar para a aula'} path={path} />

      <S.Wrapper>
        <S.ButtonNavigation
          active={navigationState.planning ? 1 : 0}
          to={
            projectId
              ? `${isOffline ? '/' : `/class/${idClass}/`}project/${projectId}/planning`
              : `${isOffline ? '/' : `/class/${idClass}/`}planning/${idLesson}`
          }
          data-testid={'Action-planning'}
          onClick={() => handleNavigation('planning')}
        >
          <UilFileInfoAlt
            size={20}
            color={navigationState.planning ? theme.colors.highPure : theme.colors.actionPure}
          />
        </S.ButtonNavigation>
        {!isOffline && status !== 'AGEN' ? (
          <>
            {statusBlock ? (
              <S.ButtonNavigation
                active={navigationState.documentations ? 1 : 0}
                to={
                  projectId
                    ? `${isOffline ? '/' : `/class/${idClass}/`}project-documentations/${projectId}`
                    : `${isOffline ? '/' : `/class/${idClass}/`}documentations/${idLessonMoment}/lesson/${idLesson}`
                }
                data-testid={'Action-documentations'}
                onClick={() => handleNavigation('documentations')}
              >
                <UilNotes
                  size={20}
                  color={
                    navigationState.documentations
                      ? theme.colors.highPure
                      : !statusBlock
                      ? theme.colors.lowLight
                      : theme.colors.actionPure
                  }
                />
              </S.ButtonNavigation>
            ) : (
              <S.ButtonAssessment active={!statusBlock} to={''}>
                <UilNotes size={20} color={theme.colors.lowLight} />
              </S.ButtonAssessment>
            )}

            {statusBlock ? (
              <S.ButtonNavigation
                active={navigationState.assessment ? 1 : 0}
                to={
                  projectId
                    ? `${isOffline ? '/' : `/class/${idClass}/`}project-assessment/${projectId}`
                    : `${isOffline ? '/' : `/class/${idClass}/`}lesson-assessment/${idLessonMoment}/lesson/${idLesson}`
                }
                data-testid={'Action-assessment'}
                onClick={() => handleNavigation('assessment')}
              >
                <UilStar
                  size={20}
                  color={navigationState.assessment ? theme.colors.highPure : theme.colors.actionPure}
                />
              </S.ButtonNavigation>
            ) : (
              <S.ButtonAssessment to={''}>
                <UilStar size={20} color={theme.colors.lowLight} />
              </S.ButtonAssessment>
            )}
          </>
        ) : (
          <>
            <S.ButtonAssessment active={!statusBlock} to={''}>
              <UilNotes size={20} color={theme.colors.lowLight} />
            </S.ButtonAssessment>
            <S.ButtonAssessment to={''}>
              <UilStar size={20} color={theme.colors.lowLight} />
            </S.ButtonAssessment>
          </>
        )}

        {statusBlock ? (
          <S.ButtonNavigation
            active={navigationState.BNCCRelations}
            to={addOffline(
              projectId
                ? `${isOffline ? '/' : `/class/${idClass}/`}project-bncc-relations/${projectId}`
                : `${isOffline ? '/' : `/class/${idClass}/`}lesson-bncc-relations/${idLessonMoment}/lesson/${idLesson}`,
            )}
            data-testid={'Action-BNCCRelations'}
            onClick={() => handleNavigation('BNCCRelations')}
          >
            <UilBookReader
              size={20}
              color={navigationState.BNCCRelations ? theme.colors.highPure : theme.colors.actionPure}
            />
          </S.ButtonNavigation>
        ) : (
          <S.ButtonAssessment to={''}>
            <UilBookReader size={20} color={theme.colors.lowLight} />
          </S.ButtonAssessment>
        )}
      </S.Wrapper>
    </S.Container>
  )
}
