import React from 'react'

import { UilCalender } from '@iconscout/react-unicons'
import dayjs from 'dayjs'

import { ButtonComponent } from '~/components'
import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import { handleStatusIcon } from '~/validations/handleStatusIcon'
import { labelDefault } from '~/validations/labelDefault'
import { labelFormat } from '~/validations/labelFormat'

import * as S from './ButtonStatus.styles'

interface ButtonStatusProps {
  size: 'small' | 'medium' | 'large'
  variant: 'solid' | 'outline' | 'text'
  onClick: () => void
  loadingStatus: boolean
  loading: boolean
  isToday: (date: Date) => boolean
  newDateHour: Date
  nuOrder: number | undefined
  today: Date
  statusCode: string
  isFilteredEmpty: boolean
  showBtn: boolean
  momentBefore: Array<any>
  isOffline: boolean
  isSameProfessor: boolean
  isClassInStatus?: boolean
  isMobile: boolean | undefined
  setShowTimeModal: () => void
  dateHour: string
}

const ButtonStatus: React.FC<ButtonStatusProps> = ({
  size,
  variant,
  onClick,
  loadingStatus,
  loading,
  isToday,
  newDateHour,
  today,
  statusCode,
  momentBefore,
  nuOrder,
  isOffline,
  isSameProfessor,
  isClassInStatus,
  isMobile,
  setShowTimeModal,
  dateHour,
}) => {
  const previousClass = momentBefore[0] || null
  const iconEnd = handleStatusIcon(statusCode)
  const text = labelFormat(statusCode)
  const color = isSameProfessor && isClassInStatus ? 'success' : 'disabled'
  const haveClassBefore =
    momentBefore &&
    momentBefore.length > 0 &&
    momentBefore[0].nuOrder !== nuOrder &&
    !['AVPE', 'FINA', 'CANC'].includes(momentBefore[0].coMomentStatus) &&
    !['AUPE', 'AVPE', 'FINA', 'PEND'].includes(statusCode) &&
    isSameProfessor

  const canStart = ['PEND', 'AVPE', 'AUPE'].includes(statusCode)
    ? true
    : dayjs(newDateHour).isBefore(today) || dayjs(newDateHour).isSame(today)

  const isFinished = ['FINA'].includes(statusCode)
  const isScheduled = ['AGEN'].includes(statusCode)

  const isClassBlockedTooltip = (
    <DSTooltip
      text={`A aula ${previousClass?.txTitle} que está agendada para o dia ${dayjs(previousClass?.dtSchedule).format(
        'DD/MM/YYYY',
      )} está ${labelDefault(previousClass?.coMomentStatus)}.`}
      position="top"
    >
      <ButtonComponent
        size="large"
        variant="solid"
        text={`Professor, a ${
          previousClass?.dtSchedule
            ? `aula do dia ${dayjs(previousClass.dtSchedule).format('DD/MM/YYYY')}`
            : 'última aula'
        } não foi realizada`}
        iconEnd={handleStatusIcon('BLOCK') || undefined}
        color="unavaible"
        disabled={isOffline || !isSameProfessor || !isClassInStatus}
      />
    </DSTooltip>
  )

  const plannedLessonButton = (
    <S.WrapperButton>
      <S.TextLabel>Planejada para {dateHour}</S.TextLabel>
      <ButtonComponent
        size="large"
        fontSize={isMobile ? 'small' : 'large'}
        loading={loadingStatus}
        variant="outline"
        text="Alterar data de realização da aula"
        iconEnd={<UilCalender />}
        onClick={() => setShowTimeModal()}
        disabled={!isClassInStatus}
        data-testid="postpone-class-button"
      />
    </S.WrapperButton>
  )

  if (haveClassBefore) {
    return isClassBlockedTooltip
  }

  if (['AUPL', 'AGUA'].includes(statusCode) && !isToday(newDateHour)) {
    return plannedLessonButton
  }
  if (isOffline || !isSameProfessor || !isClassInStatus) {
    return (
      <ButtonComponent
        size={size}
        variant={variant}
        text={text}
        iconEnd={iconEnd || undefined}
        color="unavaible"
        disabled
      />
    )
  }

  const canProceed = (!isFinished || !isScheduled) && canStart

  return (
    <>
      {statusCode !== 'CANC' ? (
        <ButtonComponent
          data-testid={`ButtonStatus${text}`}
          size={size}
          variant={variant}
          text={text}
          onClick={canProceed ? onClick : undefined}
          iconEnd={iconEnd || undefined}
          color={isFinished || isScheduled || isOffline ? 'unavaible' : color}
          loading={loading}
          disabled={isFinished || isScheduled}
        />
      ) : (
        <ButtonComponent
          size={size}
          variant="disabled"
          text="Aula cancelada"
          iconEnd={iconEnd || undefined}
          color="disabled"
          loading={loading}
          disabled={true}
        />
      )}
    </>
  )
}

export default ButtonStatus
