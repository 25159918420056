import React from 'react'

import { Box, Divider } from '@mui/material'

import { useGetStudentsGroupLesson } from '~/services/Students'

import { SkeletonComponent } from '~/components'

import { TabFirstStep } from '../components/TabFirstStep'
import * as S from '../styles'

interface LessonFormProps {
  lessonStep: []
  tabActived: number
  handleActiveNewTab: () => object
  mutateAssessment: () => object
  mutateAssessmentBatch: () => object
  status: string
  firstEvidence: any
  idLessonMoment: number
  loading: boolean
  idClass: number
  isClassInStatus: boolean
}

const LessonForm: React.FC<LessonFormProps> = ({
  lessonStep,
  status,
  firstEvidence,
  idLessonMoment,
  mutateAssessment,
  mutateAssessmentBatch,
  idClass,
  isClassInStatus,
  loading,
}) => {
  const handleAssessment = (id: string, coAssessment: string, idEvidence: string) => {
    mutateAssessment({
      body: { idMomentGroup: id, coAssessment, idEvidence },
      idLessonMoment: String(idLessonMoment),
      idClass: String(idClass),
    })
  }

  const { data: dataStudentsGroup } = useGetStudentsGroupLesson(Number(idLessonMoment))

  const handleAssessmentBatch = (body: { idLessonMoment: string }) => {
    body.idLessonMoment = String(idLessonMoment)
    body.idClass = String(idClass)
    mutateAssessmentBatch(body)
  }

  const allMomentActivities = []

  const filteredActivities = lessonStep.flatMap((step: any, stepIndex: number) =>
    step.lessonActivity
      .map((activity: any, activityIndex: number) => ({
        stepIndex,
        activityIndex,
        idEvidence: activity?.lessonActivityEvidence?.[0]?.evidenceGrade?.idEvidence,
        idLessonMomentActivity: activity.lessonMomentActivity[0]?.idLessonMomentActivity,
        ...activity,
      }))
      .filter((activity, index, self) => {
        const isDuplicate = self.filter((item) => item.idEvidence === activity.idEvidence).length > 1

        if (isDuplicate) {
          allMomentActivities.push(activity.idLessonMomentActivity)
        }

        const isFirstOccurrence =
          self.findIndex((item) => item.stepIndex === activity.stepIndex && item.idEvidence === activity.idEvidence) ===
          index

        return isFirstOccurrence
      }),
  )

  const remainingActivity = filteredActivities[1]

  const updatedGroup = dataStudentsGroup?.group?.map((groupItem: any) => {
    if (allMomentActivities?.includes(groupItem.idLessonMomentActivity)) {
      return {
        ...groupItem,
        idLessonMomentActivity: remainingActivity?.lessonMomentActivity[0]?.idLessonMomentActivity,
      }
    }

    return groupItem
  })

  if (dataStudentsGroup && dataStudentsGroup.group) {
    dataStudentsGroup.group = updatedGroup
  }

  const studentsCountByLessonMomentActivity = (dataStudentsGroup?.group || []).reduce((acc: any, groupItem: any) => {
    if (!acc[groupItem.idLessonMomentActivity]) {
      acc[groupItem.idLessonMomentActivity] = 0
    }
    acc[groupItem.idLessonMomentActivity] += 1
    return acc
  }, {})

  const validLessonMomentActivities = Object.keys(studentsCountByLessonMomentActivity || {}).filter(
    (key) => studentsCountByLessonMomentActivity[key] > 0,
  )

  const validFilteredActivities = (filteredActivities || []).filter((activity: any) =>
    validLessonMomentActivities.includes(activity.idLessonMomentActivity?.toString()),
  )

  return (
    <>
      <S.Content sx={{ marginTop: '150px' }}>
        {loading ? (
          <Box mt={5} p={2} display="flex" flexDirection="column" gap={2}>
            <SkeletonComponent variant="rounded" width={'100%'} height={52} />
            <Box mt={2} display="flex" flexDirection="row" gap={2} justifyItems={'center'}>
              <SkeletonComponent variant="rounded" width={60} height={20} />
              <SkeletonComponent variant="rounded" width={200} height={20} />
              <SkeletonComponent variant="rounded" width={200} height={20} />
            </Box>
            <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
              <SkeletonComponent variant="rounded" width={'100%'} height={20} />
              <SkeletonComponent variant="rounded" width={'30%'} height={20} />
            </Box>
            <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
              <SkeletonComponent variant="rounded" width={'100%'} height={150} />
            </Box>
          </Box>
        ) : lessonStep && lessonStep.length > 0 ? (
          (() => {
            // Índice global para as atividades
            let globalIndex = 0

            return validFilteredActivities.length > 0 ? (
              validFilteredActivities.map((activity: any, activityIndex: number) => {
                globalIndex += 1

                return (
                  <>
                    <TabFirstStep
                      key={`${activity.stepIndex}-${activity.activityIndex}`}
                      data-testId={`drag-${activityIndex}`}
                      isClassInStatus={isClassInStatus}
                      indexEvidence={globalIndex}
                      lengthEvidence={validFilteredActivities.length}
                      idLessonActivity={activity.idLessonActivity}
                      status={status as string}
                      idLessonMomentActivity={activity?.lessonMomentActivity?.[0]?.idLessonMomentActivity as number}
                      handleAssessment={handleAssessment}
                      handleAssessmentBatch={handleAssessmentBatch}
                      evidenceDescription={
                        activity?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.txName as string
                      }
                      compentencyName={
                        activity?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.competence
                          ?.txName as string
                      }
                      txName={activity?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.txName as string}
                      txIconSkill={
                        activity?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.txImagePath as string
                      }
                      group={dataStudentsGroup}
                      txIconCompetency={
                        activity?.lessonActivityEvidence?.[0]?.evidenceGrade?.evidence?.ability?.competence
                          ?.txImagePath as string
                      }
                      idEvidence={activity?.lessonActivityEvidence?.[0]?.evidenceGrade?.idEvidence}
                    />
                    <Divider sx={{ padding: 1 }} />
                  </>
                )
              })
            ) : (
              <Box mt={5} p={2} display="flex" flexDirection="column" gap={2}>
                <SkeletonComponent variant="rounded" width={'100%'} height={52} />
                <Box mt={2} display="flex" flexDirection="row" gap={2} justifyItems={'center'}>
                  <SkeletonComponent variant="rounded" width={60} height={20} />
                  <SkeletonComponent variant="rounded" width={200} height={20} />
                  <SkeletonComponent variant="rounded" width={200} height={20} />
                </Box>
                <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
                  <SkeletonComponent variant="rounded" width={'100%'} height={20} />
                  <SkeletonComponent variant="rounded" width={'30%'} height={20} />
                </Box>
                <Box mt={2} display="flex" flexDirection="column" gap={1} justifyItems={'center'}>
                  <SkeletonComponent variant="rounded" width={'100%'} height={150} />
                </Box>
              </Box>
            )
          })()
        ) : null}
      </S.Content>
    </>
  )
}

export default LessonForm
