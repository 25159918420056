import { DropdownSkillComponent } from '~/components'

import * as S from './FixedEvidences.styles'

interface IFixedEvidenceProps {
  fixedSkill: {
    evidence: {
      txName: string
      ability: {
        txName: string
        txImagePath: string
        competence: {
          txName: string
          txImagePath: string
        }
      }
    }
  }
  index?: number
}

interface IFixedEvidenceComponentProps {
  steps: any
}

const FixedEvidence = ({ fixedSkill, index }: IFixedEvidenceProps) => {
  return (
    <S.ContainerEvidence data-testid={`ClassASETs${index}`}>
      <DropdownSkillComponent
        txSkillName={fixedSkill?.evidence.ability?.txName}
        iconCompetency={fixedSkill?.evidence.ability?.competence?.txImagePath}
        iconSkill={fixedSkill?.evidence.ability?.txImagePath}
        txSkillDescription={fixedSkill?.evidence.txName}
        txBncc=""
        txCompetency={fixedSkill?.evidence.ability?.competence?.txName}
      />
    </S.ContainerEvidence>
  )
}

export const FixedEvidencesComponent = ({ steps }: IFixedEvidenceComponentProps) => {
  return (
    <S.Container>
      <S.Title>ASETs da aula</S.Title>
      {steps?.map((item: any, index: number) => (
        <FixedEvidence index={index} key={item.idLessonStep} fixedSkill={item.lessonActivityEvidence} />
      ))}
    </S.Container>
  )
}
