import { Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const Container = styledMUI(Box)(() => ({
  maxHeight: '85vh',
  maxWidth: '768px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    maxHeight: '360px',
  },
}))

export const WrapperTitle = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ContainertTitle = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center',
  paddingBottom: '16px',
}))
export const ContentIcon = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  width: '40px',
  backgroundColor: '#E8F6FF',
  borderRadius: '50px',
}))

export const ButtonClose = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: 'none',
  backgroundColor: '#fff',
  padding: 0,
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))

export const ContentDescription = styledMUI(Box)(() => ({
  overflowY: 'auto',
  borderRadius: '8px',
  marginTop: '24px',
}))

export const Divider = styledMUI(Box)(() => ({
  width: '1080px',
  height: '1px',
  backgroundColor: `${theme.colors.highDark}`,
  margin: '0px 0px 0px -48px;',
  '@media (max-width: 600px)': {
    width: '600px',
  },
  '@media (max-width: 550px)': {
    width: '550px',
  },
  '@media (max-width: 500px)': {
    width: '500px',
  },
  '@media (max-width: 450px)': {
    width: '450px',
  },
}))
