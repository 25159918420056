import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import { useSnackbar } from '~/_context/Snackbar'

import { useServiceSendDocumentation, useServiceUpdateDocumentation } from '~/services/Documentation'
import { useGetProjectInformation } from '~/services/Project'
import { useGetStudentsGroupProject } from '~/services/Students'
import { IStudentsProjectList } from '~/services/Students/types'

import { MultiSelectComponent, MultiSelectStudentComponent, SkeletonComponent } from '~/components'
import { FileUpload } from '~/components/Modals/ModalDocumentation/components/FileUpload'
import { IValuesProject } from '~/components/Modals/ModalDocumentation/ModalDocumentation.interfaces'

import { EvidenceFixed } from '~/pages/private/Fund2/Project/interfaces'

import * as S from '../ModalDocumentation.styles'

interface IDocumentFormProject {
  projectId: number
  type: 'add' | 'edit'
  values?: IValuesProject
  handleShowDocumentModal: (value: boolean) => void
  setShowSubmittedModal: (value: boolean) => void
  setShowCancelModal: (value: boolean) => void
  onLoadingSubmitChange: (value: boolean) => void
}

interface IProjectDocumentForm {
  idProjectMoment?: number
  idProjectStage?: number
  txMomentNotes: string
  skill?: string
  studentId?: number[]
  evidenceId?: number[]
  media?: []
  students?: string[]
}

interface DocumentFormRef {
  submitForm: () => void
}

// eslint-disable-next-line react/display-name
export const DocumentFormProject = forwardRef<DocumentFormRef, IDocumentFormProject>(
  (
    {
      projectId,
      type,
      values,
      handleShowDocumentModal,
      setShowSubmittedModal,
      setShowCancelModal,
      onLoadingSubmitChange,
    },
    ref,
  ) => {
    const { isLoading, data: dataProject } = useGetProjectInformation(Number(projectId))

    const {
      data: dataSuccessDocumentation,
      mutate: handleSendDocs,
      isLoading: isLoadingSubmit,
      error: errorDataSubmit,
      isSuccess: isSuccessSubmit,
    } = useServiceSendDocumentation()

    const {
      mutate: handleUpdateDocs,
      isLoading: isLoadingUpdateDoc,
      error: errorDataUpdateDoc,
      isSuccess: isSuccessUpdateDoc,
    } = useServiceUpdateDocumentation()

    useEffect(() => {
      onLoadingSubmitChange(isLoadingSubmit)
    }, [isLoadingSubmit, onLoadingSubmitChange])

    const { data: students } = useGetStudentsGroupProject(Number(projectId))

    const data = dataProject
    const { idClass } = useParams()

    const queryClient = useQueryClient()

    const { showSnackbarError } = useSnackbar()

    const studentsAttendance = students?.group?.filter((student) => student.inAttendance === true)
    const studentsAttendanceFiltered = studentsAttendance?.map((item) => item.studentClass.student)

    const [studentsState, setStudentsState] = useState<IStudentsProjectList | undefined>(students)

    const [documentFiles, setDocumentFiles] = useState([])
    const [totalExceed, setTotalExceed] = useState<boolean>(false)
    const [totalSize, setTotalSize] = useState(0)
    const [selectedStudents, setSelectedStudents] = useState<Array<string>>(['allstudents'])

    const [skillOptions, setSkillOptions] = useState([])

    const [projectStageNumber, setProjectStageNumber] = useState<number | undefined>(undefined)

    const [documentSkills, setDocumentSkills] = useState<number[]>([])

    const [isLoadingSendMedia, setIsLoadingSendMedia] = useState(false)

    const [documentForm, setDocumentForm] = useState<IProjectDocumentForm>({
      txMomentNotes: '',
      idProjectStage: 0,
      skill: '',
      media: [],
      idProjectMoment: Number(projectId),
      students: [],
    })

    const isTxMomentNotesEmpty = documentForm.txMomentNotes.trim() === ''
    const [errorTxMomentNotes, setErrorTxMomentNotes] = useState<string | null>(null)
    const isClassInStatus = data?.class?.inStatus

    const formRef = useRef()

    useEffect(() => {
      if ((!isLoadingSubmit && errorDataSubmit) || (!isLoadingUpdateDoc && errorDataUpdateDoc)) {
        const errorMessage =
          errorDataSubmit?.response?.data.message || errorDataUpdateDoc?.response?.data.message || 'Ocorreu um erro.'
        showSnackbarError(errorMessage)
      }
    }, [isLoadingSubmit, errorDataSubmit, isLoadingUpdateDoc, errorDataUpdateDoc, showSnackbarError])

    useEffect(() => {
      if (type === 'edit' && values) {
        setDocumentForm({
          txMomentNotes: values?.txMomentNotes || '',
          idProjectStage: values?.idProjectStage,
          skill: '',
          media: [],
          students: values?.projectStudentDocumentationList?.map((student) => student.idUserStudent),
        })
        if (values?.evidenceIds) {
          setDocumentSkills(values?.evidenceIds)
        }
        if (values?.projectStudentDocumentationList) {
          if (studentsAttendanceFiltered?.length === values?.projectStudentDocumentationList?.length) {
            setSelectedStudents(['allstudents'])
          } else {
            setSelectedStudents(values?.projectStudentDocumentationList?.map((student) => student.idUserStudent))
          }
        }
        if (values?.documentFiles) {
          setDocumentFiles(values?.documentFiles)
        }
        setProjectStageNumber(Number(values.idProjectStage))
      }
    }, [studentsAttendanceFiltered?.length, type, values, handleShowDocumentModal])

    useEffect(() => {
      if (dataSuccessDocumentation) {
        setShowSubmittedModal(true)
      }
    }, [dataSuccessDocumentation, setShowSubmittedModal])

    useEffect(() => {
      const handleCloseModal = () => {
        queryClient.invalidateQueries(['getDocumentation'])
        handleShowDocumentModal(false)
        setShowSubmittedModal(true)
        setDocumentSkills([])
        setSelectedStudents([])
      }
      if (!isLoadingUpdateDoc && isSuccessUpdateDoc) {
        handleCloseModal()
      }
      if (!isLoadingSubmit && isSuccessSubmit) {
        handleCloseModal()
        setDocumentForm({
          txMomentNotes: '',
          idProjectStage: 0,
          skill: '',
          media: [],
          students: [],
        })
        setDocumentFiles([])
        setTotalSize(0)
        setShowSubmittedModal(true)
      }
    }, [
      isLoadingUpdateDoc,
      isSuccessUpdateDoc,
      isLoadingSubmit,
      queryClient,
      isSuccessSubmit,
      handleShowDocumentModal,
      setShowSubmittedModal,
    ])

    useEffect(() => {
      if (projectStageNumber) {
        setStudentsState((prevStudentsState) => {
          return {
            ...prevStudentsState,
            group: students?.group?.filter(
              (student) => student.idProjectStage === projectStageNumber && student.inAttendance === true,
            ),
          }
        })
      } else {
        setStudentsState(students)
      }
    }, [projectStageNumber, students])

    useEffect(() => {
      setSkillOptions(getSkills())
      // eslint-disable-next-line
    }, [data])

    const getSkills = () => {
      const evidenceFixed: EvidenceFixed[] | undefined = data?.projectMomentStage?.[0]?.projectStage?.evidenceFixed
      const skillsArr: any[] = []

      evidenceFixed &&
        evidenceFixed.forEach((evidenceFixed: EvidenceFixed) => {
          if (evidenceFixed && evidenceFixed.idEvidence && evidenceFixed.ability?.txName) {
            const existingEvidence = skillsArr.find((skill) => skill?.evidenceId === evidenceFixed.idEvidence)
            if (!existingEvidence) {
              skillsArr.push({
                evidenceId: evidenceFixed.idEvidence,
                txName: evidenceFixed.ability.txName,
              })
            }
          }
        })

      data?.projectMomentStage?.forEach((item: any) => {
        const evidenceId = item?.projectStage?.evidenceVariable?.idEvidence
        const txName = item?.projectStage?.evidenceVariable?.ability?.txName

        if (evidenceId !== undefined && txName !== undefined) {
          if (!skillsArr.some((skill) => skill?.evidenceId === evidenceId)) {
            skillsArr.push({
              evidenceId: evidenceId,
              txName: txName,
            })
          }
        }
      })
      return skillsArr
    }

    const handleFormChange = (e: any, type?: string) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const [idProjectStage, idProjectMoment] = e?.target?.value?.split('-')

      if (type !== 'text') {
        setSelectedStudents(['allstudents'])

        if (idProjectStage === undefined || idProjectStage === 0) {
          setProjectStageNumber(undefined)
        } else {
          setProjectStageNumber(Number(idProjectStage))
        }
      }

      if (Number(idProjectStage) && Number(idProjectMoment)) {
        setDocumentForm((prevFormData: any) => {
          return {
            ...prevFormData,
            idProjectStage,
            idProjectMoment,
          }
        })
      } else if (Number(idProjectStage) && !Number(idProjectMoment) && e?.target?.name === 'idProjectStage') {
        setDocumentForm((prevFormData: any) => {
          return {
            ...prevFormData,
            [e.target.name]: idProjectStage,
            idProjectMoment: '',
          }
        })
      } else {
        setDocumentForm((prevFormData: any) => {
          return {
            ...prevFormData,
            [e.target.name]: e.target.value,
            idProjectMoment: '',
          }
        })
      }
    }

    const handleSelectDelete = (value: number) => {
      setDocumentSkills((prevValues) =>
        prevValues.filter((prevValue) => {
          return prevValue != value
        }),
      )
    }

    const handleSelect = (e: any) => {
      setDocumentSkills(e.target.value)
    }

    const validateStudents = (selectedStudents: string[]) =>
      (selectedStudents?.length === 0 || selectedStudents?.length === 1) && selectedStudents[0] === 'allstudents'
        ? null
        : selectedStudents

    const handleSubmit = (event: Event) => {
      event.preventDefault()
      const mediaInformationToPersist = documentFiles
        .filter((file: any) => file.idMedia !== null && file.idMedia !== undefined)
        .map((file: any) => file.idMedia)

      const listIdMediaInformation = documentFiles
        .filter((file) => file.idMediaInformation !== null && file.idMediaInformation !== undefined)
        .map((file) => file.idMediaInformation)

      const formCreate = {
        ...(documentForm.idProjectStage && { idMomentStage: documentForm?.idProjectStage }),
        txMomentNotes: documentForm?.txMomentNotes,
        userStudentId: validateStudents(selectedStudents),
        evidenceId: documentSkills.length === 0 ? null : documentSkills,
        coTemporaryMediaList: mediaInformationToPersist.length > 0 ? mediaInformationToPersist : [],
      }
      const formEdit = {
        IdMediaToPersist: listIdMediaInformation.length > 0 ? listIdMediaInformation : [],
        txMomentNotes: documentForm?.txMomentNotes,
        ...(validateStudents(selectedStudents) && { userStudentId: validateStudents(selectedStudents) }),
        ...(documentForm.idProjectStage && { idMomentStage: documentForm?.idProjectStage }),
        evidenceId: documentSkills.length === 0 ? [] : documentSkills,
        coTemporaryMediaList: mediaInformationToPersist.length > 0 ? mediaInformationToPersist : null,
      }

      if (type === 'edit') {
        handleUpdateDocs({
          body: formEdit,
          idMoment: Number(projectId),
          idDocumentation: values?.idProjectDocumentation as number,
          idClass: Number(idClass),
        })
      } else {
        handleSendDocs({
          body: formCreate,
          idMoment: Number(projectId),
          idClass: Number(idClass),
        })
      }

      setSelectedStudents([])

      queryClient.invalidateQueries('getProjectDocumentation').then()
    }

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        if (isTxMomentNotesEmpty) {
          setErrorTxMomentNotes('Por favor, preencha este campo.')
          return
        }

        setErrorTxMomentNotes(null)

        setIsLoadingSendMedia(true)
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
      },
    }))

    const handleSelectStudent = (idUserStudent: string) => {
      if (idUserStudent === 'allstudents') {
        setSelectedStudents(['allstudents'])
      } else if (selectedStudents?.length === studentsAttendanceFiltered?.length - 1) {
        setSelectedStudents(['allstudents'])
      } else {
        setSelectedStudents((prevSelectedStudents) => {
          const isSelected = prevSelectedStudents?.includes(idUserStudent)
          if (isSelected) {
            return prevSelectedStudents.filter((student) => student !== idUserStudent)
          } else {
            const updatedSelectedStudents = prevSelectedStudents?.filter((student) => student !== 'allstudents')
            return [...updatedSelectedStudents, idUserStudent]
          }
        })
      }
    }

    const handleTotalExceed = (value: boolean) => {
      setTotalExceed(value)
    }

    const handleClearSelectStudent = () => {
      setSelectedStudents([])
    }

    if (isLoading) {
      return (
        <Box gap={'20px'} display={'grid'}>
          <SkeletonComponent variant={'text'} width={'100%'} height={50} />
          <SkeletonComponent variant={'text'} width={'100%'} height={50} />
          <SkeletonComponent variant={'text'} width={'100%'} height={50} />
          <SkeletonComponent variant={'text'} width={'100%'} height={200} />
        </Box>
      )
    }

    return (
      <>
        <S.Form onSubmit={(event) => handleSubmit(event)} ref={formRef}>
          <S.InputDiv>
            <S.InputLabel>Selecione uma ou mais Habilidades (opcional)</S.InputLabel>

            <MultiSelectComponent
              placeHolder="Selecione uma habilidade"
              values={documentSkills}
              options={skillOptions}
              handleDelete={handleSelectDelete}
              handleChange={handleSelect}
            />
          </S.InputDiv>

          {studentsState && (
            <MultiSelectStudentComponent
              selectedStudents={selectedStudents}
              handleSelectStudent={handleSelectStudent}
              type={'project'}
              key={studentsState?.group?.length}
              students={studentsState}
              handleClearSelectStudent={handleClearSelectStudent}
            />
          )}

          <S.InputDiv>
            <S.InputLabel htmlFor="document-text">Escreva a sua documentação</S.InputLabel>

            <S.TextInput
              id="document-text"
              className={'styled-scroll-s'}
              placeholder="Escreva sua documentação"
              onChange={(e) => handleFormChange(e, 'text')}
              name="txMomentNotes"
              value={documentForm?.txMomentNotes}
              required
            />
            {errorTxMomentNotes && <S.ErrorMessage>{errorTxMomentNotes}</S.ErrorMessage>}
            <FileUpload
              documentFiles={documentFiles}
              setDocumentFiles={setDocumentFiles}
              handleTotalExceed={handleTotalExceed}
              totalExceed={totalExceed}
              setTotalSize={setTotalSize}
              setIsLoadingSendMedia={setIsLoadingSendMedia}
              isLoadingSendMedia={isLoadingSendMedia}
              totalSize={totalSize}
              isClassInStatus={isClassInStatus}
            />
          </S.InputDiv>
        </S.Form>
      </>
    )
  },
)
