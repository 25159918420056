/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useCallback, useMemo } from 'react'
import toast, { Toaster } from 'react-hot-toast'

import theme from '~/styles/theme'
interface IToastProviderProps {
  children: JSX.Element | JSX.Element[]
}

interface IToastContextProps {
  showToastSuccess: (message: string) => void
  showToastError: (message: string) => void
  showToastLoading: (message: string) => string
  closeToast: () => void
}

const ToastContext = createContext<IToastContextProps>({
  showToastSuccess: () => {},
  showToastError: () => {},
  showToastLoading: () => '',
  closeToast: () => {},
})

const ToastProvider = ({ children }: IToastProviderProps) => {
  const showToastSuccess = useCallback((message: string) => {
    toast.success(<span data-testid="msg-success">{message}</span>, {
      style: {
        background: theme.colors.feedback.success.main,
        color: theme.colors.highPure,
      },
    })
  }, [])

  const showToastError = useCallback((message: string) => {
    toast.error(<span data-testid="msg-error">{message}</span>, {
      style: {
        background: theme.colors.feedback.error.main,
        color: theme.colors.highPure,
      },
    })
  }, [])

  const showToastLoading = useCallback((message: string) => {
    return toast.loading(message, {
      style: {
        background: theme.colors.highPure,
        color: theme.colors.lowPure,
      },
    })
  }, [])

  const closeToast = useCallback(() => {
    toast.dismiss()
  }, [])

  const toastProviderValues = useMemo(
    () => ({ showToastSuccess, showToastError, showToastLoading, closeToast }),
    [showToastSuccess, showToastError, showToastLoading, closeToast],
  )

  return (
    <ToastContext.Provider value={toastProviderValues}>
      {children}
      <Toaster position="top-center" />
    </ToastContext.Provider>
  )
}

const useToast = () => useContext(ToastContext)

export { ToastProvider, useToast }
