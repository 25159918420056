import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetProjectInformation } from '~/services/Project'
import { useProjectDetailTrails } from '~/services/Trails'

import { IProjectPlanningPageContextProps, IProjectPlanningPageProviderProps } from './interface'

const ProjectPlanningPageContext = createContext<IProjectPlanningPageContextProps>({
  isLoading: false,
  projectMomentStage: undefined,
  guidance: '',
  txOrientation: '',
  txTitleLesson: '',
  fixedEvidence: {
    txAbility: '',
    txEvidence: '',
    txIconSkill: '',
    txIconCompetency: '',
    txCompetency: '',
  },
  titleNuOrder: 0,
})

const ProjectPlanningPageProvider = ({ children }: IProjectPlanningPageProviderProps) => {
  const { projectId, idProjectTrack } = useParams()

  const { data } = useGetProjectInformation(Number(projectId), { enabled: !!projectId })

  const isUsingProjectData = !!projectId
  const shouldFetchTrack = !!idProjectTrack

  const { data: dataTrack } = useProjectDetailTrails(idProjectTrack, 1, shouldFetchTrack)

  const dataSource = isUsingProjectData ? data : dataTrack

  // Variáveis baseadas na fonte de dados
  const titleNuOrder = dataSource?.projectTrackStage?.txTitle || dataSource?.txTitle

  const txTitleLesson = dataSource?.projectTrackStage?.txDescription || dataSource?.txDescription

  const txOrientation = dataSource?.projectTrackStage?.txGuidanceCode || dataSource?.txGuidanceCode

  const stageOrientations =
    dataSource?.projectMomentStage?.[0]?.projectStage?.projectStageOrientation ||
    dataSource?.projectStage?.[0]?.projectStageOrientations

  const fixedEvidence = useMemo(() => {
    if (isUsingProjectData) {
      return dataSource?.projectMomentStage?.[0]?.projectStage?.evidenceFixed
    } else {
      return [dataSource?.projectStage?.[0]?.evidences?.find((evidence) => evidence.coEvidenceType === 'FIX')]
    }
  }, [isUsingProjectData, dataSource])

  const projectMomentStage = isUsingProjectData ? dataSource?.projectMomentStage : dataSource?.projectStage?.[0]

  const status = (data?.momentStatus?.coMomentStatus as string) || 'AGEN'

  const isLoading = false

  const lessonPlanningPageProviderValues = useMemo(() => {
    return {
      isLoading,
      fixedEvidence: fixedEvidence,
      projectMomentStage,
      txTitleLesson: txTitleLesson || '',
      titleNuOrder,
      txOrientation,
      status,
      stageOrientations,
    }
    // eslint-disable-next-line
  }, [
    isLoading,
    projectMomentStage,
    fixedEvidence,
    titleNuOrder,
    txTitleLesson,
    txOrientation,
    status,
    stageOrientations,
  ])

  return (
    <ProjectPlanningPageContext.Provider value={lessonPlanningPageProviderValues}>
      {children}
    </ProjectPlanningPageContext.Provider>
  )
}

const useProjectPlanningPageContext = () => useContext(ProjectPlanningPageContext)

export { ProjectPlanningPageProvider, useProjectPlanningPageContext }
