import { Box, Card, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const ContainerLink = styledMUI(Box)(() => ({
  textDecoration: 'none',
  cursor: 'pointer',
}))

export const Container = styledMUI(Card)<{ disabled: boolean | undefined }>(({ disabled }) => ({
  height: '56px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  backgroundColor: disabled ? '#E0E0E0' : '#E8F6FF',
  borderRadius: '8px',
  boxShadow: 'none',

  '&:hover': {
    boxShadow: theme.shadows.level3,
  },
}))

export const TextLabel = styledMUI(Typography)<{ disabled: boolean | undefined }>(({ disabled }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '120%',
  color: disabled ? '#A3A3A3' : '#0095FF',
}))

export const Icon = styledMUI(Box)<{ disabled: boolean | undefined }>(({ disabled }) => ({
  color: disabled ? '#A3A3A3' : '#0095FF',
}))

export const LabelWrapper = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
}))

export const ContentArrow = styledMUI(Box)<{ disabled: boolean | undefined }>(({ disabled }) => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: disabled ? '#A3A3A3' : '#90D1FF',
  borderRadius: '50%',
}))
