import { useNavigate } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'

import DSTooltip from '~/components/DesingSystem/Tooltip/Tooltip'

import { ICardNavigationComponentProps } from './CardNavigation.interfaces'
import * as S from './CardNavigation.styles'

export const CardNavigationComponent = ({
  icon,
  label,
  pathNavigation,
  disabled,
  tooltip,
  testId,
}: ICardNavigationComponentProps) => {
  const navigate = useNavigate()

  const handleNavigation = () => {
    if (!disabled) {
      navigate(pathNavigation)
    }
  }

  if (tooltip) {
    return (
      <DSTooltip text={tooltip} position={'top'}>
        <span>
          <S.ContainerLink onClick={handleNavigation}>
            <S.Container disabled={disabled}>
              <S.LabelWrapper>
                <S.Icon disabled={disabled}>{icon}</S.Icon>
                <S.TextLabel disabled={disabled} variant="caption">
                  {label}
                </S.TextLabel>
              </S.LabelWrapper>

              <S.ContentArrow disabled={disabled}>
                <UilArrowRight size={20} color={disabled ? '#E0E0E0' : '#0095FF'} />
              </S.ContentArrow>
            </S.Container>
          </S.ContainerLink>
        </span>
      </DSTooltip>
    )
  }

  return (
    <S.ContainerLink as="div" data-testid={testId} onClick={handleNavigation}>
      <S.Container disabled={disabled}>
        <S.LabelWrapper>
          <S.Icon disabled={disabled}>{icon}</S.Icon>
          <S.TextLabel disabled={disabled} variant="caption">
            {label}
          </S.TextLabel>
        </S.LabelWrapper>

        <S.ContentArrow disabled={disabled}>
          <UilArrowRight size={20} color={disabled ? '#E0E0E0' : '#0095FF'} />
        </S.ContentArrow>
      </S.Container>
    </S.ContainerLink>
  )
}
