import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9fafc;
  zindex: 1;
`

export const Content = styled.div`
  height: auto;
  width: 1024px;
  background-color: #fff;
`

export const ContentList = styledMUI(Box)(() => ({
  width: '100%',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '90px',
}))

export const Description = styled.h3`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
`

export const ContentTxGrade = styled.div`
  margin: 16px 0;
`

export const ContentListTxGrade = styled.div`
  margin: 16px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
`

export const TxGrade = styled.h2`
  //styleName: Text/Bold/Heading/Large;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #0095ff;
`

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  color: '#000',
}))

export const Tag = styled.div`
  max-width: 142px;
  height: 24px;
  gap: 0px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.midDark};

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    min-width: fit-content;
    color: ${theme.colors.midDark};
  }
`
