import { Link } from 'react-router-dom'

import { Box, Popover, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import { styled, css } from '@mui/material/styles'

import { IQuickNavigationProps } from '~/components/Navigation/QuickNavigation/QuickNavigation.interfaces'
import { IStyledProps } from '~/components/Navigation/StepsTabs/StepsTabs.interfaces'

import { colorsStepsActived, colorsStepsDisabled } from '~/validations'

import theme from '~/styles/theme'

export const ContainerDivider = styled(Box)(() => ({
  minWidth: '85px',
  maxWidth: '240px',
  height: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '12px',
  justifyContent: 'space-evenly',
  color: theme.colors.midDark,
  gap: 4,
  fontWeight: 400,
  border: `solid 2px ${theme.colors.midMedium}`,
  borderRadius: '50px',
}))

export const RowNumbers = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<{ color?: string; isHighlighted: boolean }>(({ color, isHighlighted }) => ({
  width: '24px',
  height: '24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  fontSize: '12px',
  color: isHighlighted ? 'white' : theme.colors.midDark,
  fontWeight: 400,
  backgroundColor: isHighlighted ? color : 'transparent',
  borderRadius: isHighlighted ? '50px' : '0',
}))

//isNavigation
export const Tabs = styledMUI(Box)<IStyledProps>(({ actived }) => ({
  height: '32px',
  width: '116px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: actived === 'true' ? '#FFDBE9' : '#FFF',
  border: actived === 'true' ? '0' : `1px solid ${theme.colors.quaternaryPure}`,
  borderRadius: '50px',
  padding: '4px',
  gap: '4px',
}))

export const ButtonSecondStepNavigation = styled(Link)<IQuickNavigationProps>`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  text-decoration: none;

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStep' &&
    !active &&
    css`
      color: ${theme.colors.quaternaryPure};
    `}

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStep' &&
    active &&
    css`
      border: 1px solid ${theme.colors.quaternaryPure};
      background: ${theme.colors.quaternaryPure};
      color: #fff;
    `}

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepA' &&
    !active &&
    css`
      color: ${theme.colors.quaternaryPure};
    `}

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepA' &&
    active &&
    css`
      border: 1px solid ${theme.colors.quaternaryPure};
      background: ${theme.colors.quaternaryPure};
      color: #fff;
    `}

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepB' &&
    !active &&
    css`
      color: ${theme.colors.quaternaryPure};
    `}

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepB' &&
    active &&
    css`
      border: 1px solid ${theme.colors.quaternaryPure};
      background: ${theme.colors.quaternaryPure};
      color: #fff;
    `}

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepC' &&
    !active &&
    css`
      color: ${theme.colors.quaternaryPure};
    `}

  ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepC' &&
    active &&
    css`
      border: 1px solid ${theme.colors.quaternaryPure};
      background: ${theme.colors.quaternaryPure};
      color: #fff;
    `}

    ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepD' &&
    !active &&
    css`
      color: ${theme.colors.quaternaryPure};
    `}
    ${({ variantnavigate, active }) =>
    variantnavigate === 'secondStepD' &&
    active &&
    css`
      border: 1px solid ${theme.colors.quaternaryPure};
      background: ${theme.colors.quaternaryPure};
      color: #fff;
    `}
`

export const Tab = styledMUI(Box)<IStyledProps>(({ actived, type, status }) => ({
  height: '44px',
  width: status ? '100%' : '33%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: actived === 'true' ? colorsStepsActived(type) : colorsStepsDisabled(type),
  borderRadius: '50px',
  gap: '10px',
  cursor: 'pointer',
}))

export const TextTab = styledMUI(Typography)<IStyledProps>(({ actived, type }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: actived === 'true' ? '#fff' : colorsStepsActived(type),
}))

export const InfoText = styled(Typography)(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '100%',
  color: `${theme.colors.lowLight}`,
}))

export const TrailTitle = styled('h3')({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
  color: `${theme.colors.lowPure}`,
})

export const ContentOrderSchedule = styled(Box)(() => ({
  width: '17.69px',
  height: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '64px',
  backgroundColor: '#BDC3CF',
}))

export const TextOrderSchedule = styled(Typography)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '100%',
  color: '#fff',
}))

export const OrderScheduleBox = styled(Box)(() => ({
  backgroundColor: '#fff',
  padding: '8px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  zIndex: '99999999',
}))

export const TooltipContainer = styledMUI(Box)({
  position: 'relative',
})

export const TooltipContainerHover = styledMUI(Box)({
  '&:hover $customPaper': {
    position: 'relative',
  },
})

export const PopoverContainer = styledMUI(Popover)({
  backgroundColor: theme.colors.highPure,
  width: '334px',
  top: '4px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
})
